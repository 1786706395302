import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const TruckIcon: React.FC<SvgIconProps> = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props}>
            <svg
                fill={props.fill}
                version="1.1"
                viewBox="0 0 796.929 796.928"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M179.162,603.989c0-30.482-25.248-55.188-56.433-55.188c-31.155,0-56.434,24.705-56.434,55.188
			c0,30.484,25.279,55.19,56.434,55.19C153.914,659.18,179.162,634.474,179.162,603.989z"
                />
                <path
                    d="M328.125,610.54c0-30.479-25.272-55.189-56.433-55.189c-31.186,0-56.433,24.711-56.433,55.189
			c0,30.485,25.247,55.19,56.433,55.19C302.851,665.731,328.125,641.026,328.125,610.54z"
                />
                <path
                    d="M703.105,603.989c0-30.482-25.263-55.188-56.434-55.188c-31.17,0-56.434,24.705-56.434,55.188
			c0,30.484,25.264,55.19,56.434,55.19C677.843,659.18,703.105,634.474,703.105,603.989z"
                />
                <path d="M493.637,345.899c0,0-30.758,121.8-48.345,132.257h48.345V345.899z" />

                <polygon points="6.146,412.571 6.146,476.556 141.848,476.556 66.486,412.571" />

                <path
                    d="M466.531,214.073c0,0-168.801-140.59-424.369-55.542c0,0-61.386,44.259-36.018,173.134
				c0,0,100.574,117.094,240.582,140.74l7.097,4.15h177.185c0,0,54.442-75.404,55.938-186.308
				C486.947,290.248,488.025,245.315,466.531,214.073z"
                />
                <path
                    d="M781.245,492.198V360.575h0.001c0-25.228-12.638-47.401-31.906-61.331c-12.979-9.364-28.864-15.102-46.233-15.102H601.353
			h-30.459h-7.882V492.2h-23.465V300.551h-32.688v191.647H1.221v95.854h32.656c7.685-41.209,44.5-72.475,88.854-72.475h148.963
			c44.354,0,81.17,31.266,88.854,72.475h197.271c7.688-41.209,44.488-72.475,88.854-72.475s81.171,31.266,88.854,72.475h61.401
			v-95.854H781.245z M632.354,436.097h-42.115V313.318h42.115V436.097z M646.67,436.086V313.318h56.434
			c0,0,44.174,3.823,44.174,38.333l0.002,84.437L646.67,436.086L646.67,436.086z"
                />
            </svg>
        </SvgIcon>
    );
};

export default TruckIcon;
