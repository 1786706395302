import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { DefaultDataGridPageSize } from "@src/constants";
import { RootState } from "@src/store";

interface ProfileState {
    data: ProfileRequest;
}

export interface ProfileRequest {
    id: CinnamonID;
    user: string;
    firstName: string;
    lastName: string;
    mail: string;
    gender: string;
    role: string;
    permissions: string[];
    teams: unknown[];
    createdAt: string;
    updatedAt: string | null;
    lockedAt: string | null;
    lockedBy: unknown | null;
    settings: UserSettings;
}

interface UserSettings {
    elementsPerPage: number;
    defaultDateRange: string;
    notificationMails: unknown[];
    notificationPopups: unknown[];
    showButtonText: boolean;
    darkModeEnabled: boolean;
    enlargeFirstReceipt: boolean;
    guiPreferences: {
        system_teams_sorting: unknown[];
        debitors_documents_offer_sorting: string;
        debitors_documents_filters: string;
        creditors_data_sorting: {
            name: string;
            sort: {
                direction: string;
                priority: number;
            };
        }[];
        creditors_documents_invoice_sorting: string;
        creditors_documents_filters: string;
        system_users_filters: {
            type: string;
        };
        system_users_sorting_user: {
            name: string;
            sort: {
                direction: string;
                priority: number;
            };
        }[];
    };
}

const initialState: ProfileState = {
    data: {
        createdAt: "",
        firstName: "",
        gender: "",
        id: -1,
        lastName: "",
        lockedAt: null,
        lockedBy: null,
        mail: "",
        permissions: [],
        role: "",
        settings: {
            darkModeEnabled: false,
            defaultDateRange: "last90days",
            elementsPerPage: DefaultDataGridPageSize,
            enlargeFirstReceipt: false,
            guiPreferences: {
                creditors_data_sorting: [],
                creditors_documents_filters: "",
                creditors_documents_invoice_sorting: "",
                debitors_documents_filters: "",
                debitors_documents_offer_sorting: "",
                system_teams_sorting: [],
                system_users_filters: {
                    type: "",
                },
                system_users_sorting_user: [],
            },
            notificationMails: [],
            notificationPopups: [],
            showButtonText: false,
        },
        teams: [],
        updatedAt: null,
        user: "",
    },
};

export const profileSlice = createSlice({
    initialState,
    name: "profile",
    reducers: {
        updateElementsPerPage: (state, action: PayloadAction<number>) => {
            state.data.settings.elementsPerPage = action.payload;
        },
        updateProfile: (state, action: PayloadAction<ProfileRequest>) => {
            state.data = action.payload;
        },
        updateSettings: (state, action: PayloadAction<UserSettings>) => {
            state.data.settings = action.payload;
        },
    },
});

export const { updateProfile, updateSettings, updateElementsPerPage } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile.data;

export default profileSlice.reducer;
