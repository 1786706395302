import { Build } from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { ProfileRequest, selectProfile } from "@src/features/profile/profile-slice";
import { useAppSelector } from "@src/hooks/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import { showTranslations } from "translation-check";

const DevMenu: React.FC = () => {
    const profile = useAppSelector<ProfileRequest>(selectProfile);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { t, i18n } = useTranslation();

    // only admins can see this
    if (profile.role !== "role_admin") {
        return null;
    }

    // only show this menu when the account belongs to Digital Control
    if (!profile.mail.endsWith("@digital-control.biz") && profile.mail.endsWith("@digital-control.de")) {
        return null;
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0, ml: 2 }}>
            <Tooltip title={t("component.dev-menu.open-notifications")}>
                <IconButton
                    color="inherit"
                    onClick={handleOpenUserMenu}
                >
                    <Build />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                id="dev-menu-appbar"
                keepMounted
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
                sx={{ mt: "45px" }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
            >
                <MenuItem onClick={() => showTranslations(i18n)}>
                    <Typography textAlign="center">{t("component.dev-menu.translation-check")}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                    component="a"
                    disabled={CINNAMON_GITLAB_PIPELINE_ID === null}
                    href={
                        CINNAMON_GITLAB_PIPELINE_ID
                            ? `https://gitlab.com/dcbiz/products/cinnamon/cinnamon-frontend/-/pipelines/${CINNAMON_GITLAB_PIPELINE_ID}`
                            : undefined
                    }
                    target="_blank"
                >
                    <Typography textAlign="center">
                        {t("component.dev-menu.build", { build: CINNAMON_GITLAB_COMMIT_SHA })}
                    </Typography>
                </MenuItem>
                <MenuItem disabled>
                    <Typography textAlign="center">
                        {t("component.dev-menu.build-time", { time: new Date(CINNAMON_BUILD_TIME).toLocaleString() })}
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default React.memo(DevMenu);
