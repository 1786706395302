import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

export interface NavigationBarState {
    navigationOpen: boolean;
}

const initialState: NavigationBarState = {
    navigationOpen: false,
};

export const navigationBarSlice = createSlice({
    initialState,
    name: "navigationBar",
    reducers: {
        setNavigationOpenStatus: (state, action: PayloadAction<boolean>) => {
            state.navigationOpen = action.payload;
        },
    },
});

export const { setNavigationOpenStatus } = navigationBarSlice.actions;

export const getNavigationOpenStatus = (state: RootState) => state.navigationBar;

export default navigationBarSlice.reducer;
