import { get } from "@src/utils/request";

export interface MediaDeskData {
    url: string;
    businessAreas: string[];
}

export interface FeatureData {
    feature_zterm: boolean;
    feature_datev: boolean;
    feature_job_comment: boolean;
    feature_mediadesk: boolean;
    feature_sap: boolean;
    // This is extra config for the media desk feature. Here comes some data needed for the feature.
    mediadesk?: MediaDeskData;
}

export const fetchFeatures = async (): Promise<FeatureData> => {
    return get<FeatureData>("/config/features.json");
};
