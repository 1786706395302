import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { Team } from "@src/api/teams";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { TaxZone } from "@src/api/types/TaxZone";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { Locks, Type } from "@src/utils/locks";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const BusinessAreasContext = React.createContext<BusinessArea[]>([]);

export const fetchBusinessAreas = async () => {
    const res = await get<ApiResponse<BusinessArea>>("/internalApi/businessAreas", { cache: true, ttl: 5 * MINUTE });
    return res.items;
};

export const fetchBusinessArea = async (id: CinnamonID): Promise<BusinessArea> =>
    await get<BusinessArea>(`/internalApi/businessAreas/${id}`);

export const createBusinessArea = async (businessArea: BusinessAreaPostData) => {
    await post("/internalApi/businessAreas", { body: businessArea });
    await burstBusinessAreasCache();
};

export const updateBusinessArea = async (id: CinnamonID, businessArea: BusinessAreaPostData) => {
    await put(`/internalApi/businessAreas/${id}`, { body: businessArea });
    await burstBusinessAreasCache();
};

export const fetchLockedBusinessArea = async (id: CinnamonID): Promise<BusinessArea> =>
    Locks.acquire([Type.BusinessAreas], [id as CinnamonID]);

export const releaseLockedBusinessArea = async (id: CinnamonID): Promise<BusinessArea | null> =>
    Locks.release([Type.BusinessAreas], [id as CinnamonID]);

export const burstBusinessAreasCache = async () => deleteRelatedItemsFromCacheStorage("/internalApi/businessAreas");

export interface BusinessAreaPostData extends Omit<BusinessArea, "subAreas" | "defaultTeam"> {
    subAreas: {
        businessArea: CinnamonID;
        overrideName: string;
    }[];
    defaultTeam: CinnamonID;
}

export interface BusinessArea extends SymfonyFields {
    id: CinnamonID;
    name: string;
    shortName: string;
    defaultTeam: Team;
    subAreas: BusinessAreaDTO[];
    expenseAccountRules: {
        id: CinnamonID;
        expenseAccount: string;
        taxZone: TaxZone;
        vatValue: number;
    }[];
    debitorAccount: string;
    creditorAccount: string;
    costCenterInland: string;
    costCenterEc: string;
    costCenterNec: string;
    expenseAccountInland: string;
    expenseAccountEc: string;
    expenseAccountNec: string;
}

export interface BusinessAreaDTO {
    id: CinnamonID;
    name: string;
    shortName: string;
    overrideName?: string;
}
