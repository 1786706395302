import { FeatureData, fetchFeatures } from "@src/api/feature-flags";
import { useStateWithDataFetcher } from "@src/hooks/data-fetcher";
import log from "@src/utils/logger";
import React, { createContext, FC } from "react";

interface FeatureFlagProviderProps {
    children: React.ReactNode;
}

interface FeatureFlagsContextType {
    features: FeatureData;
}

export const initialState: FeatureData = {
    feature_datev: false,
    feature_job_comment: false,
    feature_mediadesk: false,
    feature_sap: false,
    feature_zterm: false,
};

export const FeatureFlags = createContext<FeatureFlagsContextType>({ features: initialState });

export const FeatureFlagsProvider: FC<FeatureFlagProviderProps> = ({ children }) => {
    const [features] = useStateWithDataFetcher<FeatureData>(initialState, async () => {
        try {
            log.debug("Fetching feature flags");
            return await fetchFeatures();
        } catch (error) {
            log.error("Failed to fetch feature flags" + error);
            return initialState;
        }
    });

    return <FeatureFlags.Provider value={{ features }}>{children}</FeatureFlags.Provider>;
};
