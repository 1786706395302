import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

interface ExpandTableIndex {
    [key: string]: boolean;
}

export interface DashboardState {
    expand: {
        default: boolean;
        userSpecific?: ExpandTableIndex;
    };
    showStorageCapacity: boolean;
}

const initialState: DashboardState = {
    expand: {
        default: true,
    },
    showStorageCapacity: true,
};

export const dashboardSlice = createSlice({
    initialState,
    name: "dashboard",
    reducers: {
        toggleExpandDefault: state => {
            state.expand.userSpecific = undefined;
            state.expand.default = !state.expand.default;
        },
        toggleStorageCapacity: state => {
            state.showStorageCapacity = !state.showStorageCapacity;
        },
        updateExpandUserSpecific: (state, action: PayloadAction<ExpandTableIndex>) => {
            state.expand.userSpecific = action.payload;
        },
    },
});

export const { toggleStorageCapacity, toggleExpandDefault, updateExpandUserSpecific } = dashboardSlice.actions;

export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
