import { checkIfMaintenanceIsOver } from "@src/components/maintenance/Maintenance";
import keepAliveTask from "@src/features/keepalive/keepalive-task";
import updateMaintenanceModeTask from "@src/features/maintanance/maintenance-task";
import updateNotificationsTask from "@src/features/notification/update-notifications-task";
import updateProfileTask from "@src/features/profile/profile-task";

import SchedulerTask from "./SchedulerTask";

const SECOND = 1000;
const MINUTE = 60 * SECOND;

const tasks: SchedulerTask[] = [
    { delay: 30 * SECOND, fn: updateNotificationsTask, name: "UpdateNotifications", triggerInstantly: true },
    { delay: 3 * MINUTE, fn: keepAliveTask, name: "KeepAlive" },
    { delay: 30 * MINUTE, fn: updateProfileTask, name: "UpdateProfile", triggerInstantly: true },
    { delay: 15 * MINUTE, fn: updateMaintenanceModeTask, name: "UpdateMaintenanceMode", triggerInstantly: true },

    // Check the function checkIfMaintanenceIsOver. It prevents the app from causing to many request by checking
    // if the maintenance mode is active or not before sending a request.
    { delay: 15 * SECOND, fn: checkIfMaintenanceIsOver, name: "CheckIfMaintenanceModeIsOver", triggerInstantly: true },
];

export default tasks;
