import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

export interface GlobalSearchStore {
    companyType: CompanyTypes;
    type: DebitorTypes | CreditorTypes;
}

export enum CompanyTypes {
    All = "all",
    Debitors = "debitors",
    Creditors = "creditors",
}

export enum DebitorTypes {
    Offer = "offer",
    Order = "order",
    Invoice = "invoice",
    SerialInvoice = "serial_invoice",
    Warning = "warning",
}

export enum CreditorTypes {
    Order = "creditor_order",
    Invoice = "invoice",
    Credit = "credit",
    Credit_cancellation = "credit_cancellation",
}

const initialState: GlobalSearchStore = {
    companyType: CompanyTypes.All,
    type: CreditorTypes.Order,
};

export const globalSearchSlice = createSlice({
    initialState,
    name: "globalSearch",
    reducers: {
        setGlobalSearch: (state, action: PayloadAction<GlobalSearchStore>) => {
            state.companyType = action.payload.companyType;
            state.type = action.payload.type;
        },
    },
});

export const { setGlobalSearch } = globalSearchSlice.actions;

export const selectGlobalSearch = (state: RootState) => state.globalSearch;

export default globalSearchSlice.reducer;
