import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

interface ImpersonationState {
    username: string | null;
}

const initialState: ImpersonationState = {
    username: null,
};

export const impersonationSlice = createSlice({
    initialState,
    name: "profile",
    reducers: {
        setImpersonated: (state, action: PayloadAction<string | null>) => {
            state.username = action.payload;
        },
    },
});

export const { setImpersonated } = impersonationSlice.actions;

export const selectImpersonatedUser = (state: RootState) => state.impersonation.username;

export default impersonationSlice.reducer;
