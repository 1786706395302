import { DebitorDocumentType, JobDTO } from "@src/api/documents";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { ResponseWithErrorResponse } from "@src/api/types/errors";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, post } from "@src/utils/request";

export const fetchOutbox = async (
    status: OutBoxStatusType,
    page?: number,
    pageSize?: number,
    freeText?: string,
): Promise<ApiResponse<Outbox>> => {
    const args = argsBuilder({ freeText, page, pageSize, status });
    return await get<ApiResponse<Outbox>>(`/internalApi/outbox${args}`, { cache: true, ttl: 5 * MINUTE });
};

export const sendMailOutbox = async (ids: CinnamonID[]): Promise<void | ResponseWithErrorResponse> => {
    await burstOutBoxCache();
    const body = { id: ids };
    await post("/internalApi/outbox/mail", { body });
};

export const deleteOutbox = async (ids: CinnamonID[]): Promise<void | ResponseWithErrorResponse> => {
    await burstOutBoxCache();
    const body = { id: ids };
    await delete_("/internalApi/outbox", { body });
};

export const burstOutBoxCache = async () => await deleteRelatedItemsFromCacheStorage("/internalApi/outbox");

export type OutBoxStatusType = "new" | "done";
export type OutBoxFormatType = "pdf" | "xml";
export type OutboxType = "mail" | "print";
export type OutboxLevelType = "none";

export interface Outbox {
    createdAt: string;
    debitorDocument: DebitorDocument;
    format: OutBoxFormatType;
    id: CinnamonID;
    isDeletable: boolean;
    level: OutboxLevelType;
    printedAt: string | null;
    status: OutBoxStatusType;
    type: OutboxType;
    updatedAt: string | null;
    updatedBy: {
        firstName: string;
        id: CinnamonID;
        lastName: string;
        user: string;
    } | null;
}

interface DebitorDocument {
    id: CinnamonID;
    currentNo: number;
    type: DebitorDocumentType;
    documentYear: number;
    businessArea: {
        id: CinnamonID;
        name: string;
        shortName: string;
    };
    company: {
        id: CinnamonID;
        name: string;
        customerNo: number;
        supplierNo: number;
        searchName: string;
        companyType: string;
        creditor_account: string;
        debitor_account: string;
        active: boolean;
    };
    contact: {
        id: CinnamonID;
        firstName: string;
        lastName: string;
    } | null;
    job: JobDTO;
}

export const OutboxTabsTypeArray = ["new", "done"] as const;
type OutboxTabsTypesTuple = typeof OutboxTabsTypeArray;
export type OutboxTabsType = OutboxTabsTypesTuple[number];
