import { createTheme, ThemeProvider } from "@mui/material";
import { getDesignTokens } from "@src/components/theme/DefaultTheme";
import { selectProfile } from "@src/features/profile/profile-slice";
import { useAppSelector } from "@src/hooks/redux";
import React from "react";

type colorModeType = "dark" | "light";

type Props = {
    children?: React.ReactNode;
};

const CinnamonThemeProvider: React.FC<Props> = ({ children }: Props): React.JSX.Element => {
    const profile = useAppSelector(selectProfile);
    const userColorMode: colorModeType = profile.settings.darkModeEnabled ? "dark" : "light";
    const theme = createTheme(getDesignTokens(userColorMode), [userColorMode]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CinnamonThemeProvider;
