import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { ResponseWithErrorResponse } from "@src/api/types/errors";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, post, put } from "@src/utils/request";
import React from "react";

export const ContactsContext = React.createContext<Contact[]>([]);

export const fetchContacts = async (companyId: CinnamonID): Promise<Contact[]> => {
    const res = await get<ApiResponse<Contact>>(`/internalApi/companies/${companyId}/contacts`);
    return res.items;
};

export const updateContact = async (
    companyId: CinnamonID,
    contactId: CinnamonID,
    body: ContactSubmitData,
): Promise<Contact | ResponseWithErrorResponse> =>
    put(`/internalApi/companies/${companyId}/contacts/${contactId}`, { body });

export const createContact = async (
    companyId: CinnamonID,
    body: ContactSubmitData,
): Promise<Contact | ResponseWithErrorResponse> => post(`/internalApi/companies/${companyId}/contacts`, { body });

export const deleteContact = async (companyId: CinnamonID, contactId: CinnamonID): Promise<void> =>
    delete_(`/internalApi/companies/${companyId}/contacts/${contactId}`);

export const burstContactCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/companies/");

export interface Contact extends SymfonyFields {
    id: CinnamonID;
    salutation: string;
    firstName: string;
    lastName: string;
    title: string;
    position: string;
    department: string;
    phone: string;
    email: string;
    active: boolean;
    isDefault: boolean;
    invoiceReceiver: boolean;
}

export interface ContactSubmitData {
    salutation: string;
    firstName: string;
    lastName: string;
    title: string;
    position: string;
    department: string;
    phone: string;
    email: string;
    active: boolean;
    isDefault: boolean;
    invoiceReceiver: boolean;
}
