import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import annualPlanningReducer from "@src/features/annual-planning/annual-planning-slice";
import dashboardReducer from "@src/features/dashboard/dashboard-slice";
import dataGridsSavedStateReducer from "@src/features/data-grids-saved-state/data-grids-saved-state-slice";
import creditorDocumentsFilterReducer from "@src/features/document-filters/creditor-document-filters-slice";
import debitorDocumentsFilterReducer from "@src/features/document-filters/debitor-document-filters-slice";
import quickDocumentFiltersSliceReducer from "@src/features/document-filters/quick-document-filters-slice";
import globalSearchReducer from "@src/features/global-search/global-search-slice";
import impersonationReducer from "@src/features/impersonate/impersonation-slice";
import maintenanceReducer from "@src/features/maintanance/maintenance-slice";
import navigationBarReducer from "@src/features/navigation-bar/navigation-bar-slice";
import notificationReducer from "@src/features/notification/notification-slice";
import pendingReducer from "@src/features/pending/pending-slice";
import profileReducer from "@src/features/profile/profile-slice";
import unfinishedServicesReducer from "@src/features/unfinished-services/unfinished-services-slice";

const stateIdentifier = "cinnamon.state";

const reducersNotToBePersisted = ["pending"];

const persistedState =
    stateIdentifier in localStorage ? JSON.parse(localStorage.getItem(stateIdentifier) as string) : {};

export const store = configureStore({
    enhancers: getDefaultEnhancers => {
        return getDefaultEnhancers().concat([Sentry.createReduxEnhancer({})]);
    },
    preloadedState: persistedState,
    reducer: combineReducers({
        annualPlanning: annualPlanningReducer,
        creditorDocumentFilters: creditorDocumentsFilterReducer,
        dashboard: dashboardReducer,
        dataGridsSavedState: dataGridsSavedStateReducer,
        debitorDocumentFilters: debitorDocumentsFilterReducer,
        globalSearch: globalSearchReducer,
        impersonation: impersonationReducer,
        maintenance: maintenanceReducer,
        navigationBar: navigationBarReducer,
        notification: notificationReducer,
        pending: pendingReducer,
        profile: profileReducer,
        quickDocumentFilters: quickDocumentFiltersSliceReducer,
        unfinishedServices: unfinishedServicesReducer,
    }),
});

store.subscribe(() => {
    const state = Object.fromEntries(
        Object.entries(store.getState()).filter(([key]) => reducersNotToBePersisted.indexOf(key) === -1),
    );

    localStorage.setItem(stateIdentifier, JSON.stringify(state));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
