import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { RootState } from "@src/store";
import { DataType } from "@src/types/StatisticsTypes";

export enum TableModelTypes {
    SINGLE,
    CUMULATED,
    BOTH,
}

export interface AnnualPlanningState {
    businessAreaId: CinnamonID;
    type: DataType;
    year: string;
    tableModelType: TableModelTypes | null;
}

const initialState: AnnualPlanningState = {
    businessAreaId: -1,
    tableModelType: null,
    type: DataType.Turnover,
    year: new Date().getFullYear().toString(),
};

export const annualPlanningSlice = createSlice({
    initialState,
    name: "annualPlanning",
    reducers: {
        setAnnualPlanningBusinessArea: (state, action: PayloadAction<CinnamonID>) => {
            state.businessAreaId = action.payload;
        },
        setAnnualPlanningType: (state, action: PayloadAction<DataType>) => {
            state.type = action.payload;
        },
        setAnnualPlanningYear: (state, action: PayloadAction<string>) => {
            state.year = action.payload;
        },
        setTableModelType: (state, action: PayloadAction<TableModelTypes | null>) => {
            state.tableModelType = action.payload;
        },
    },
});

export const { setAnnualPlanningBusinessArea, setAnnualPlanningType, setAnnualPlanningYear, setTableModelType } =
    annualPlanningSlice.actions;

export const selectAnnualPlanning = (state: RootState) => state.annualPlanning;

export default annualPlanningSlice.reducer;
