import { AppDispatch } from "@src/store";
import log from "@src/utils/logger";
import { get } from "@src/utils/request";

import { disableMaintenance, enableMaintenance, MaintenanceRequest, setMaintenance } from "./maintenance-slice";

const updateMaintenanceModeTask = async (dispatch: AppDispatch) => {
    try {
        const maintenanceResponse = await get<MaintenanceRequest>("/internalApi/maintenanceSchedule");

        if (maintenanceResponse.planned == undefined) {
            dispatch(
                setMaintenance({
                    duration: 0,
                    planned: null,
                }),
            );

            return;
        }

        dispatch(disableMaintenance());
        dispatch(setMaintenance(maintenanceResponse));
        log.debug("UpdateMaintenanceMode: Disable Maintenance Mode");
        // eslint-disable-next-line
    } catch (e: any) {
        if (e.status === 503) {
            log.debug("UpdateMaintenanceMode: Enable Maintenance Mode");
            dispatch(enableMaintenance());
        }
    }
};

export default updateMaintenanceModeTask;
