import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

function InitSentry() {
    Sentry.init({
        // Uncomment the line below to get console output from Sentry. Also comment out the "enabled" parameter.
        // Otherwise, Sentry won't do anything in development/local machine.
        // debug: true,

        dsn: "https://9131073ff6b623f867185e8b0bc3fb2e@o1085680.ingest.sentry.io/4506262795255808",

        // Disable Sentry in development
        enabled: CINNAMON_GITLAB_COMMIT_SHA !== "dev",
        environment: CINNAMON_ENVIRONMENT ?? "dev",

        integrations: [
            Sentry.browserTracingIntegration(),

            Sentry.reactRouterV6BrowserTracingIntegration({
                createRoutesFromChildren,
                matchRoutes,
                useEffect,
                useLocation,
                useNavigationType,
            }),

            Sentry.reactRouterV5BrowserTracingIntegration({
                history,
            }),
            Sentry.reactRouterV4BrowserTracingIntegration({
                history,
            }),

            Sentry.replayIntegration(),
        ],

        replaysOnErrorSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTar  gets: ["localhost", /^https:\/\/yourserver\.io\/internalApi/],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 1.0,
    });
}

export default InitSentry;
