import { setCreditorDocumentsFilterDocumentDate } from "@src/features/document-filters/creditor-document-filters-slice";
import { setDebitorDocumentsFilterDocumentDate } from "@src/features/document-filters/debitor-document-filters-slice";
import { AppDispatch } from "@src/store";
import log from "@src/utils/logger";
import { get } from "@src/utils/request";

import { ProfileRequest, updateProfile } from "./profile-slice";

const updateProfileTask = async (dispatch: AppDispatch) => {
    const profile = await get<ProfileRequest>("/internalApi/profile", { cache: true, ttl: 360 });

    // disable console logging for non admins
    if (profile.role !== "role_admin") {
        log.disableConsoleLogging();
    }

    dispatch(updateProfile(profile));

    dispatch(setCreditorDocumentsFilterDocumentDate(profile.settings.defaultDateRange));
    dispatch(setDebitorDocumentsFilterDocumentDate(profile.settings.defaultDateRange));
};

export default updateProfileTask;
