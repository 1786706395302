import Vendor from "@src/features/customization/Vendor";
import React, { ReactNode } from "react";

interface IfVendorProps {
    vendor: Vendor;
    children: ReactNode;
}

const IfVendor: React.FC<IfVendorProps> = ({ vendor, children }) =>
    vendor === CINNAMON_VENDOR ? <>{children}</> : null; // eslint-disable-line react/jsx-no-useless-fragment

export default React.memo(IfVendor);
