import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { EditedVat, NewVat } from "@src/types/VatTypes";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const VatsContext = React.createContext<Vat[]>([]);

export const fetchVats = async (active?: boolean): Promise<Vat[]> => {
    const args = argsBuilder({ active });
    const res = await get<ApiResponse<Vat>>(`/internalApi/vats${args}`, { cache: true, ttl: 5 * MINUTE });
    return res.items;
};

export const fetchVat = async (id: CinnamonID): Promise<Vat> =>
    await get<Vat>(`/internalApi/vats/${id}`, {
        cache: true,
        ttl: 5 * MINUTE,
    });

export const createVat = async (vat: NewVat): Promise<void> => {
    await post("/internalApi/vats", {
        body: vat,
    });
    await burstVatCaches();
};

export const updateVat = async (id: CinnamonID, vat: EditedVat): Promise<void> => {
    await put(`/internalApi/vats/${id}`, {
        body: vat,
    });
    await burstVatCaches();
};

export const burstVatCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/vats");

export interface Vat extends SymfonyFields {
    id: CinnamonID;
    name: string;
    currentValue: number;
    active: boolean;
}

export interface VatDTO {
    id: CinnamonID;
    name: string;
    currentValue: number;
}
