import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { DocumentListFilterFormInputs } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import {
    QuickDocumentFilters,
    selectQuickDocumentFilters,
    setQuickDocumentFilters,
} from "@src/features/document-filters/quick-document-filters-slice";
import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import React, { ChangeEvent, FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface DocumentQuickFilterWrapperProps {
    filter: keyof DocumentListFilterFormInputs;
    quickFilterMode?: boolean;
    children: ReactNode;
    sizes?: { xs: number };
}

export const disabledQuickFilters = ["freeText", "type"];

const DocumentQuickFilterWrapper: FC<DocumentQuickFilterWrapperProps> = ({
    filter,
    children,
    quickFilterMode = false,
    sizes = { xs: 12 },
}) => {
    const dispatch = useAppDispatch();
    const quickFilters = useAppSelector<QuickDocumentFilters>(selectQuickDocumentFilters);
    const { t } = useTranslation();

    const onCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setQuickDocumentFilters({ input: filter, value: e.target.checked }));
    };

    if (quickFilterMode && !quickFilters[filter]) {
        return null;
    }

    const renderCheckBox = (): ReactNode => {
        if (quickFilterMode) {
            return null;
        }

        // Free text filter input has an info icon instead of a checkbox
        if (filter === "freeText") {
            return (
                <Grid
                    item
                    xs={1}
                >
                    <Tooltip
                        arrow
                        sx={{
                            alignSelf: "center",
                            fontWeight: 100,
                            ml: 1,
                            mt: 0.5,
                        }}
                        title={t("pages.debitor-creditor-docs.filter.checkboxes-info")}
                    >
                        <InfoOutlinedIcon
                            color="primary"
                            cursor="help"
                            fontSize="medium"
                        />
                    </Tooltip>
                </Grid>
            );
        }

        if (disabledQuickFilters.includes(filter)) {
            return (
                <Grid
                    item
                    mb={5}
                    xs={1}
                />
            );
        }

        return (
            <Grid
                item
                xs={1}
            >
                {
                    <Checkbox
                        checked={quickFilters[filter]}
                        onChange={onCheckBoxChange}
                        sx={{ mt: -0.5 }}
                    />
                }
            </Grid>
        );
    };

    return (
        <Grid
            item
            {...sizes}
        >
            <Grid container>
                {renderCheckBox()}
                <Grid
                    item
                    xs={quickFilterMode ? 12 : 11}
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DocumentQuickFilterWrapper;
