import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

interface NotificationState {
    data: NotificationsRequest;
}

export interface NotificationsRequest {
    countTotal: number;
    countCreditor: number;
    countDebitor: number;
    creditorDocuments: CreditorDocument[];
    debitorDocuments: DebitorDocument[];
}

interface Company {
    id: number;
    name: string;
    searchName: string;
    customerNo: string;
    supplierNo: string | null;
    companyType: string;
    creditor_account: string;
    debitor_account: string;
    active: boolean;
}

interface Job {
    id: number;
    name: string;
    number: string;
}

export interface CreditorDocument {
    id: number;
    company: Company;
    currentNo: number;
    type: string;
    po: string;
    jobs: Job[];
    status: number;
}

export interface DebitorDocument {
    id: number;
    currentNo: number;
    type: string;
    documentYear: number;
    businessArea: {
        id: number;
        name: string;
        shortName: string;
    };
    company: Company;
    job: Job;
    status: number;
}

const initialState: NotificationState = {
    data: {
        countCreditor: 0,
        countDebitor: 0,
        countTotal: 0,
        creditorDocuments: [],
        debitorDocuments: [],
    },
};

export const notificationSlice = createSlice({
    initialState,
    name: "notification",
    reducers: {
        updateNotifications: (state, action: PayloadAction<NotificationsRequest>) => {
            state.data = action.payload;
        },
    },
});

export const { updateNotifications } = notificationSlice.actions;

export const selectNotifications = (state: RootState) => state.notification.data;

export default notificationSlice.reducer;
