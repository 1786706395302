import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DebitorDocumentType } from "@src/api/documents";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { DocumentListFilterFormInputs } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import { RootState } from "@src/store";

interface InitialStateData {
    data: DocumentListFilterFormInputs;
}

const initialState: InitialStateData = {
    data: {
        approvalStatus: null,
        businessAreas: [],
        cId: null,
        closed: null,
        company: null,
        currentNo: "",
        documentDate: null,
        documentYear: null,
        freeText: "",
        fromDate: null,
        hasFollowupOffer: null,
        hasUnfinishedServices: null,
        job: null,
        jobNo: "",
        paginateNo: null,
        paymentMethod: null,
        paymentStatus: "",
        po: null,
        receiptDate: null,
        runtimeStart: null,
        runtimeStop: null,
        status: null,
        toDate: null,
        type: "offer",
    },
};

export const debitorDocumentsFiltersSlice = createSlice({
    initialState,
    name: "debitorDocumentsFilters",
    reducers: {
        resetDebitorDocumentsFilterArgs: (state, action: PayloadAction<DebitorDocumentType>) => {
            state.data = {
                ...initialState.data,
                type: action.payload,
            };
        },
        setDebitorDocumentsFilterArgs: (state, action: PayloadAction<DocumentListFilterFormInputs>) => {
            state.data = action.payload;
        },
        setDebitorDocumentsFilterArgsCompany: (state, action: PayloadAction<CinnamonID>) => {
            const val = Number(action.payload);
            state.data.company = !isNaN(val) && val > 0 ? val : null;
        },
        setDebitorDocumentsFilterArgsFreeText: (state, action: PayloadAction<string>) => {
            state.data.freeText = action.payload;
        },
        setDebitorDocumentsFilterArgsType: (state, action: PayloadAction<DebitorDocumentType>) => {
            state.data.type = action.payload;
        },
        setDebitorDocumentsFilterDocumentDate: (state, action: PayloadAction<string>) => {
            state.data.documentYear = action.payload;
        },
    },
});

export const {
    setDebitorDocumentsFilterArgs,
    resetDebitorDocumentsFilterArgs,
    setDebitorDocumentsFilterArgsFreeText,
    setDebitorDocumentsFilterArgsType,
    setDebitorDocumentsFilterArgsCompany,
    setDebitorDocumentsFilterDocumentDate,
} = debitorDocumentsFiltersSlice.actions;

export const selectDebitorDocumentFilters = (state: RootState) => state.debitorDocumentFilters.data;

export default debitorDocumentsFiltersSlice.reducer;
