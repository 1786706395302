import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { ResponseWithErrorResponse } from "@src/api/types/errors";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, post, put } from "@src/utils/request";
import React from "react";

export const AddressesContext = React.createContext<Address[]>([]);

export const fetchAddresses = async (companyId: CinnamonID): Promise<Address[]> => {
    const res = await get<ApiResponse<Address>>(`/internalApi/companies/${companyId}/addresses`);
    return res.items;
};

export const updateAddress = async (
    companyId: CinnamonID,
    contactId: CinnamonID,
    body: AddressSubmitData,
): Promise<Address | ResponseWithErrorResponse> =>
    put(`/internalApi/companies/${companyId}/addresses/${contactId}`, { body });

export const createAddress = async (
    companyId: CinnamonID,
    body: AddressSubmitData,
): Promise<Address | ResponseWithErrorResponse> => post(`/internalApi/companies/${companyId}/addresses`, { body });

export const deleteAddress = async (companyId: CinnamonID, addressId: CinnamonID): Promise<void> =>
    delete_(`/internalApi/companies/${companyId}/addresses/${addressId}`);

export const burstAddressCaches = async () => deleteRelatedItemsFromCacheStorage("/internalApi/companies/");

export interface Address extends SymfonyFields {
    id: CinnamonID;
    name: string;
    tradeName: string;
    street: string;
    addition: string;
    addition2: string;
    postal: string;
    city: string;
    postboxNo: string;
    postboxPostal: string;
    postboxCity: string;
    state: string;
    country: string;
    vatCountry: string;
    taxZone: string;
    vatNo: string;
    docsViaEmail: boolean;
    docsEmail: string;
    default: boolean;
    leitwegId: string;
    xmlInvoice: boolean;
    active: boolean;
    debitorAccount: string;
}

export interface AddressSubmitData {
    name: string;
    tradeName: string;
    street: string;
    addition: string;
    addition2: string;
    postal: string;
    city: string;
    postboxNo: string;
    postboxPostal: string;
    postboxCity: string;
    state: string;
    country: string;
    vatCountry: string;
    taxZone: string;
    vatNo: string;
    docsViaEmail: boolean;
    docsEmail: string;
    default: boolean;
    leitwegId: string;
    xmlInvoice: boolean;
    active: boolean;
}
