import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { RootState } from "@src/store";
import { UnfinishedServicesDataType } from "@src/types/StatisticsTypes";

export enum TableModelTypes {
    SINGLE,
    CUMULATED,
    BOTH,
}

export interface UnfinishedServicesState {
    businessAreaId: CinnamonID;
    year: string;
    tableModelType: TableModelTypes | null;
    type: UnfinishedServicesDataType;
}

const initialState: UnfinishedServicesState = {
    businessAreaId: -1,
    tableModelType: null,
    type: UnfinishedServicesDataType.Billed,
    year: new Date().getFullYear().toString(),
};

export const unfinishedServicesSlice = createSlice({
    initialState,
    name: "unfinishedServices",
    reducers: {
        setBusinessArea: (state, action: PayloadAction<CinnamonID>) => {
            state.businessAreaId = action.payload;
        },
        setTableModelType: (state, action: PayloadAction<TableModelTypes | null>) => {
            state.tableModelType = action.payload;
        },
        setType: (state, action: PayloadAction<UnfinishedServicesDataType>) => {
            state.type = action.payload;
        },
        setYear: (state, action: PayloadAction<string>) => {
            state.year = action.payload;
        },
    },
});

export const { setBusinessArea, setYear, setType, setTableModelType } = unfinishedServicesSlice.actions;

export const selectUnfinishedServices = (state: RootState) => state.unfinishedServices;

export default unfinishedServicesSlice.reducer;
