import { Cake, Notifications } from "@mui/icons-material";
import { Badge, Box, IconButton, Menu, Stack, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { CreditorDocumentType, DebitorDocumentType } from "@src/api/documents";
import DocumentLink, { DocumentType } from "@src/components/navigation/DocumentLink";
import {
    CreditorDocument,
    DebitorDocument,
    NotificationsRequest,
    selectNotifications,
} from "@src/features/notification/notification-slice";
import { useAppSelector } from "@src/hooks/redux";
import { creditorInternalPO, debitorInternalPO } from "@src/utils/internal-po";
import React from "react";
import { useTranslation } from "react-i18next";

interface NotificationHeaderParams {
    title: string;
}

/**
 * Renders a header for a list of notifications. Is only used in the NotificationsMenu component.
 */
const NotificationHeader: React.FC<NotificationHeaderParams> = (headerParams): JSX.Element => {
    return (
        <Box>
            <Typography
                fontWeight="bold"
                sx={{ p: 1, pl: 2, textTransform: "uppercase" }}
            >
                {headerParams.title}
            </Typography>
            <Divider sx={{ my: 1 }} />
        </Box>
    );
};

interface NotificationListItemParams {
    documents: DebitorDocument[] | CreditorDocument[];
    type: "debitor" | "creditor";
}

/**
 * Renders a list of notifications for a specific document type. Is only used in the NotificationsMenu component.
 */

const NotificationList: React.FC<NotificationListItemParams> = params => {
    return (
        <>
            {params.documents.map(doc => {
                let documentType: DocumentType;
                let documentSubType: CreditorDocumentType | DebitorDocumentType;
                let internalPO: string;

                if (params.type === "debitor") {
                    documentType = DocumentType.DEBITOR_DOC;
                    documentSubType = doc.type as DebitorDocumentType;
                    internalPO = debitorInternalPO(doc as DebitorDocument);
                } else {
                    documentType = DocumentType.CREDITOR_DOC;
                    documentSubType = doc.type as CreditorDocumentType;
                    internalPO = creditorInternalPO(doc as CreditorDocument);
                }

                return (
                    <DocumentLink
                        key={`notification_menu_${doc.company.id}_${doc.id}_${doc.type}`}
                        companyId={doc.company.id}
                        documentSubType={documentSubType}
                        documentType={documentType}
                        id={doc.id}
                        sx={{
                            display: "flex",
                            m: 2,
                            textDecoration: "none",
                        }}
                        title={documentSubType}
                    >
                        <Stack>
                            <Typography fontWeight="bold">{internalPO}</Typography>
                            <Typography>{doc.company.name}</Typography>
                        </Stack>
                    </DocumentLink>
                );
            })}
        </>
    );
};

/**
 * Renders a menu with notifications. Is used in the AppBar component. When no notifications are available, the menu
 * shows a message that no notifications are available.
 */
const NotificationMenu: React.FC = () => {
    const notifications = useAppSelector<NotificationsRequest>(selectNotifications);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0, ml: 2 }}>
            <Tooltip title={t("component.notification-menu.open-notifications")}>
                <IconButton
                    color="inherit"
                    onClick={handleOpenUserMenu}
                >
                    <Badge
                        badgeContent={notifications.countTotal}
                        color="warning"
                    >
                        <Notifications />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                id="notifications-menu-appbar"
                keepMounted
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
                sx={{ mt: "45px" }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
            >
                {/* Render debitor document notifications if any are present */}
                {notifications.debitorDocuments?.length > 0 ? (
                    <NotificationHeader
                        title={t("component.notification-menu.debitor-title", {
                            number: notifications.debitorDocuments?.length,
                        })}
                    />
                ) : null}
                {notifications.debitorDocuments ? (
                    <NotificationList
                        documents={notifications.debitorDocuments}
                        type={"debitor"}
                    />
                ) : null}

                {/* Render creditor document notifications if any are present */}
                {notifications.creditorDocuments?.length > 0 ? (
                    <NotificationHeader
                        title={t("component.notification-menu.creditor-title", {
                            number: notifications.creditorDocuments?.length,
                        })}
                    />
                ) : null}
                {notifications.creditorDocuments ? (
                    <NotificationList
                        documents={notifications.creditorDocuments}
                        type={"creditor"}
                    />
                ) : null}

                {/* Render hint when no notifications are present */}
                {notifications.countTotal === 0 ? (
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", pb: 2, pt: 2 }}>
                            <Cake sx={{ fontSize: 48 }} />
                        </Box>
                        <Typography textAlign="center">{t("component.notification-menu.no-notifications")}</Typography>
                    </Box>
                ) : null}
            </Menu>
        </Box>
    );
};

export default React.memo(NotificationMenu);
