import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditorDocumentType } from "@src/api/documents";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { DocumentListFilterFormInputs } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import { RootState } from "@src/store";

interface InitialStateData {
    data: DocumentListFilterFormInputs;
}

const initialState: InitialStateData = {
    data: {
        approvalStatus: null,
        businessAreas: [],
        cId: null,
        closed: null,
        company: null,
        currentNo: "",
        documentDate: null,
        documentYear: null,
        freeText: "",
        fromDate: null,
        hasFollowupOffer: null,
        job: null,
        jobNo: "",
        paginateNo: null,
        paymentMethod: null,
        paymentStatus: "",
        po: null,
        receiptDate: null,
        runtimeStart: null,
        runtimeStop: null,
        status: null,
        toDate: null,
        type: "invoice",
    },
};

export const creditorDocumentsFiltersSlice = createSlice({
    initialState,
    name: "creditorDocumentsFilters",
    reducers: {
        resetCreditorDocumentsFilterArgs: (state, action: PayloadAction<CreditorDocumentType>) => {
            state.data = {
                ...initialState.data,
                type: action.payload,
            };
        },
        setCreditorDocumentsFilterArgs: (state, action: PayloadAction<DocumentListFilterFormInputs>) => {
            state.data = action.payload;
        },
        setCreditorDocumentsFilterArgsId: (state, action: PayloadAction<CinnamonID>) => {
            const val = Number(action.payload);
            state.data.cId = !isNaN(val) && val > 0 ? val : null;
        },
        setCreditorDocumentsFilterArgsType: (state, action: PayloadAction<CreditorDocumentType>) => {
            state.data.type = action.payload;
        },
        setCreditorDocumentsFilterDocumentDate: (state, action: PayloadAction<string>) => {
            state.data.documentYear = action.payload;
        },
        setCreditorsDocumentsFilterArgsFreeText: (state, action: PayloadAction<string>) => {
            state.data.freeText = action.payload;
        },
    },
});

export const {
    setCreditorDocumentsFilterArgs,
    resetCreditorDocumentsFilterArgs,
    setCreditorDocumentsFilterDocumentDate,
    setCreditorDocumentsFilterArgsType,
    setCreditorDocumentsFilterArgsId,
    setCreditorsDocumentsFilterArgsFreeText,
} = creditorDocumentsFiltersSlice.actions;

export const selectCreditorDocumentFilters = (state: RootState) => state.creditorDocumentFilters.data;

export default creditorDocumentsFiltersSlice.reducer;
