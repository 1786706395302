import log from "@src/utils/logger";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

const truncateMessageNum = 200;

const useInfoSnackbar = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useCallback(
        (message: string) => {
            const len = message.length;

            message = message.slice(0, truncateMessageNum) + (len > truncateMessageNum ? "..." : "");

            enqueueSnackbar(message, {
                preventDuplicate: true,
                style: { whiteSpace: "pre-line" },
                variant: "info",
            });

            log.info(message);
        },
        [enqueueSnackbar],
    );
};

export default useInfoSnackbar;
