import "./utils/wdyr";
import "./i18n";
import "dayjs/locale/de";

import { Button, Slide } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import { FeatureFlagsProvider } from "@src/components/customization/FeatureFlagProvider";
import MaintenanceMode from "@src/components/maintenance/Maintenance";
import CinnamonThemeProvider from "@src/components/theme/CinnamonThemeProvider";
import LockSnackbarHandler from "@src/components/websocket-cache-handler/LockSnackbarHandler";
import WebsocketToCacheCleaner from "@src/components/websocket-cache-handler/WebsocketToCacheCleaner";
import { DEFAULT_APP_NAME } from "@src/constants";
import { muiDatePickerAdapterLocale, muiDatePickerLocalizationProviderLocaleText } from "@src/i18n";
import { redirects, routes } from "@src/routes";
import InitSentry from "@src/sentry";
import { ConfirmProvider } from "material-ui-confirm";
import { closeSnackbar, SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Layout from "./components/layout/Layout";
import Scheduler from "./components/scheduler/Scheduler";
import tasks from "./features/scheduler/tasks";
import { WebsocketURLProvider } from "./features/WebsocketURLProvider";
import SiteNotFound from "./pages/404/SiteNotFound";
import { store } from "./store";

const CinnamonApp = () => (
    <LocalizationProvider
        adapterLocale={muiDatePickerAdapterLocale()}
        dateAdapter={AdapterDayjs}
        localeText={muiDatePickerLocalizationProviderLocaleText()}
    >
        <Provider store={store}>
            <CinnamonThemeProvider>
                <SnackbarProvider
                    TransitionComponent={Slide}
                    action={key => (
                        <Button
                            onClick={() => closeSnackbar(key)}
                            sx={{
                                height: "100%",
                                left: 0,
                                position: "absolute",
                                top: 0,
                                width: "100%",
                            }}
                        />
                    )}
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                    }}
                    maxSnack={3}
                >
                    <ConfirmProvider>
                        <WebsocketURLProvider>
                            <Scheduler tasks={tasks} />
                            <WebsocketToCacheCleaner />

                            <MaintenanceMode>
                                <FeatureFlagsProvider>
                                    <BrowserRouter>
                                        <LockSnackbarHandler />

                                        <Layout>
                                            <SentryRoutes>
                                                {Object.entries(redirects).map(([from, to]) => (
                                                    <Route
                                                        key={from}
                                                        element={
                                                            <Navigate
                                                                replace
                                                                to={to}
                                                            />
                                                        }
                                                        path={from}
                                                    />
                                                ))}

                                                {Object.entries(routes).map(([route, Component]) => (
                                                    <Route
                                                        key={route}
                                                        path={route}
                                                    >
                                                        <Route
                                                            element={<Component />}
                                                            index
                                                        />
                                                    </Route>
                                                ))}

                                                {/* 404 */}
                                                <Route
                                                    element={<SiteNotFound />}
                                                    path="*"
                                                />
                                            </SentryRoutes>
                                        </Layout>
                                    </BrowserRouter>
                                </FeatureFlagsProvider>
                            </MaintenanceMode>
                        </WebsocketURLProvider>
                    </ConfirmProvider>
                </SnackbarProvider>
            </CinnamonThemeProvider>
        </Provider>
    </LocalizationProvider>
);

const container = document.getElementById("app");

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#usage-with-routes--component
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (container !== null) {
    document.title = CINNAMON_APPNAME ?? DEFAULT_APP_NAME;

    if (CINNAMON_MUI_LICENSE_KEY) {
        LicenseInfo.setLicenseKey(CINNAMON_MUI_LICENSE_KEY);
    }

    InitSentry();

    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <CinnamonApp />
        </React.StrictMode>,
    );
}
