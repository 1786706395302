import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { Role } from "@src/types/Roles";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export const RolesContext = React.createContext<Role[]>([]);

export const fetchRoles = async (): Promise<ApiResponse<Role>> =>
    await get<ApiResponse<Role>>("/internalApi/roles", { cache: true, ttl: 5 * MINUTE });

export const fetchRole = async (id: CinnamonID): Promise<Role> => await get<Role>(`/internalApi/roles/${id}`);

export const createRole = async (role: Role): Promise<void> => {
    await post("/internalApi/roles", { body: role });
    await burstRolesCache();
};

export const updateRole = async (id: CinnamonID, role: Role): Promise<void> => {
    await put(`/internalApi/roles/${id}`, { body: role });
    await burstRolesCache();
};

export const burstRolesCache = async () => deleteRelatedItemsFromCacheStorage("/internalApi/roles");
