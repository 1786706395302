import { Location } from "@remix-run/router/history";
import { Address } from "@src/api/addresses";
import { BusinessArea } from "@src/api/business-areas";
import { UserDTO } from "@src/api/creditors";
import { CreditorDocument, DebitorDocument } from "@src/api/documents";
import { Job } from "@src/api/jobs";
import { Product, ProductCategory } from "@src/api/products";
import { Team } from "@src/api/teams";
import { TextSnippet } from "@src/api/textSnippets";
import { VatDTO } from "@src/api/vats";
import { selectProfile } from "@src/features/profile/profile-slice";
import useDefaultSnackbar from "@src/hooks/default-snackbar";
import useFDWebsocket, { WebsocketMessage, WSTitleEnum, WSTitleType } from "@src/hooks/fd-websocket";
import useInfoSnackbar from "@src/hooks/info-snackbar";
import { useAppSelector } from "@src/hooks/redux";
import { CompanyDTO, Payment } from "@src/types/Payment";
import { Role } from "@src/types/Roles";
import { creditorInternalPO, debitorInternalPO } from "@src/utils/internal-po";
import logger from "@src/utils/logger";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

/**
 * This component listens to websocket messages and displays a snackbar when a document is locked or unlocked.
 *
 * Note:
 * The blue and yellow snackbar messages shown in the right bottom corner of the screen are most likely originate
 * from this component.
 *
 * @constructor
 */
const LockSnackbarHandler = () => {
    const { lastJsonMessage } = useFDWebsocket();
    const { t } = useTranslation();
    const location = useLocation();

    const infoSnackbar = useInfoSnackbar();
    const defaultSnackbar = useDefaultSnackbar();

    const profile = useAppSelector(selectProfile);

    // The lastMessageTimeStamp is used as additional check to prevent old messages being displayed.
    // Take a look at the two useEffect below. The first one always updates the lastMessageTimeStamp.
    // The second one checks if the lastRelevantMessage is not null and if the lastMessageTimeStamp is not older
    // than 1 second. So While the user is on a different location and a new message arrives, the lastMessageTimeStamp
    // will be updated. If the user then navigates to a location where the message is relevant,
    // but old the message is suppressed!
    const [lastMessageTimeStamp, setLastMessageTimeStamp] = useState<number>(Date.now());
    useEffect(() => {
        setLastMessageTimeStamp(Date.now());
    }, [lastJsonMessage]);

    useEffect(() => {
        if (
            lastJsonMessage === null ||
            !lastJsonMessage.channel ||
            !location ||
            !isMessageRelevant(location, lastJsonMessage)
        ) {
            return; // Do nat show anything for irrelevant messages.
        }

        if (lastMessageTimeStamp >= Date.now() - 1000) {
            if (lastJsonMessage.payload.data == null) {
                return;
            }

            let documentNumber = "";
            let documentName = "";

            [documentNumber, documentName] = messageIdentifiers(lastJsonMessage);

            if (
                "lockedBy" in lastJsonMessage.payload.data &&
                lastJsonMessage.payload.data?.lockedBy?.id == profile.id
            ) {
                return;
            }

            if (lastJsonMessage.payload.title.search("unlocked") != -1) {
                infoSnackbar(
                    t(`component.lock-snackbar.${lastJsonMessage.payload.title}`, {
                        name: documentName,
                        number: documentNumber,
                    }),
                );
            }

            if (lastJsonMessage.payload.title.search("locked") != -1) {
                defaultSnackbar(
                    t(`component.lock-snackbar.${lastJsonMessage.payload.title}`, {
                        name: documentName,
                        number: documentNumber,
                    }),
                );
            }
        }
    }, [defaultSnackbar, infoSnackbar, lastJsonMessage, lastMessageTimeStamp, location, profile.id, t]);

    return null;
};

function isMessageRelevant(location: Location, lastMessage: WebsocketMessage): boolean {
    let mainLocationPart = location.pathname.split("/")[1];

    const irrelevantTitles: WSTitleType[] = [
        WSTitleEnum.DEBITOR_DOCUMENT_UPDATED,
        WSTitleEnum.CREDITOR_DOCUMENT_UPDATED,
    ];

    // Do not show snackbar for irrelevant Payload Titles.
    if (irrelevantTitles.includes(lastMessage.payload.title)) {
        return false;
    }

    switch (mainLocationPart) {
        case "debitors":
            return (
                lastMessage.channel === "debitor_document" ||
                lastMessage.channel === "debitors" ||
                lastMessage.channel === "debitor-creditors"
            );
        case "creditors":
            return (
                lastMessage.channel === "creditor_document" ||
                lastMessage.channel === "creditors" ||
                lastMessage.channel === "debitor-creditors"
            );
        case "productcategories":
            return lastMessage.channel === "product_category";
        case "settings":
        // fallthrough
        case "templates":
        // fallthrough
        case "system":
            // The settings/system area is seperated from the other areas with a second part in the path.
            mainLocationPart = location.pathname.split("/")[2];

            // Also in the settings/system area the trailing "s" is missing in the channel name.
            return lastMessage.channel === mainLocationPart;
        default:
            return `${lastMessage.channel}s` === mainLocationPart;
    }
}

function messageIdentifiers(lastMessage: WebsocketMessage): [documentNumber: string, documentName: string] {
    let documentNumber = "";
    let documentName = "";

    switch (lastMessage.channel) {
        case "debitor_document":
            documentNumber = debitorInternalPO(lastMessage.payload.data as DebitorDocument);
            break;
        case "creditor_document":
            documentNumber = creditorInternalPO(lastMessage.payload.data as CreditorDocument);
            break;
        case "job":
            documentNumber = (lastMessage.payload.data as Job).number;
            documentName = (lastMessage.payload.data as Job).name;
            break;
        case "debitors":
        case "creditors":
        case "debitor-creditors":
            // Contains a special case for company addresses and contacts. Happens when the user
            // opens and closes a company address or contact.
            if (lastMessage.payload.title.search("address") != -1) {
                documentNumber = (lastMessage.payload.data as Address).id.toString();
                documentName = (lastMessage.payload.data as Address).name;
                break;
            } else if (lastMessage.payload.title.search("contact") != -1) {
                documentNumber = (lastMessage.payload.data as UserDTO).id.toString();
                documentName = (lastMessage.payload.data as UserDTO).name;
                break;
            }

            documentNumber = (lastMessage.payload.data as CompanyDTO).id.toString();
            documentName = (lastMessage.payload.data as CompanyDTO).name;
            break;
        case "product":
            documentNumber = (lastMessage.payload.data as Product).id.toString();
            documentName = (lastMessage.payload.data as Product).name;
            break;
        case "product_category":
            documentName = (lastMessage.payload.data as ProductCategory).name;
            break;
        case "payment":
            documentNumber = (lastMessage.payload.data as Payment).id.toString();
            documentName = (lastMessage.payload.data as Payment).description ?? "";
            break;
        case "business_areas":
            documentNumber = (lastMessage.payload.data as BusinessArea).id.toString();
            documentName = (lastMessage.payload.data as BusinessArea).name;
            break;
        case "vats":
            documentNumber = (lastMessage.payload.data as VatDTO).id.toString();
            documentName = (lastMessage.payload.data as VatDTO).name;
            break;
        case "users":
            documentNumber = (lastMessage.payload.data as UserDTO).id.toString();
            documentName = (lastMessage.payload.data as UserDTO).user;
            break;
        case "teams":
            documentNumber = (lastMessage.payload.data as Team).id.toString();
            documentName = (lastMessage.payload.data as Team).name;
            break;
        case "roles":
            documentNumber = (lastMessage.payload.data as Role).id.toString();
            documentName = (lastMessage.payload.data as Role).name;
            break;
        case "snippets":
            documentNumber = (lastMessage.payload.data as TextSnippet).id.toString();
            documentName = (lastMessage.payload.data as TextSnippet).name;
            break;
        default:
            logger.error("Missing document switch case", lastMessage);
            documentNumber = "MISSING DOCUMENT SWITCH CASE";
    }

    return [documentNumber, documentName];
}

export default LockSnackbarHandler;
