enum LogLevel {
    Trace,
    Debug,
    Info,
    Warn,
    Error,
    Fatal,
}

class Logger {
    private readonly level: LogLevel = LogLevel.Info;
    private consoleLoggingEnabled = true;

    constructor() {
        if (CINNAMON_DEVMODE) {
            this.level = LogLevel.Debug;
        }
    }

    public disableConsoleLogging() {
        this.consoleLoggingEnabled = false;
    }

    private print(level: LogLevel, message: string, data: object = {}) {
        if (this.level > level) {
            return;
        }

        const logLevelStr = this.prefixByLevel(level);
        const logFunc = this.logFunctionByLevel(level);

        // TODO: send data to backend / log somewhere else...
        /*const structuredLog = {
            level: logLevelStr,
            timestamp: Date.now(),
            message,
            data,
        }*/

        if (level === LogLevel.Fatal) {
            throw Error(`${message} - ${JSON.stringify(data, null, "    ")}`);
        }

        if (!this.consoleLoggingEnabled) {
            return;
        }

        const logMessage = `[${logLevelStr}] ${message}`;
        if (Object.keys(data).length > 0) {
            logFunc(logMessage, data);
        } else {
            logFunc(logMessage);
        }
    }

    private logFunctionByLevel(level: LogLevel): (...data: unknown[]) => void {
        /*eslint-disable*/
        // we do not allow usage of console commands in the project except for this function
        switch (level) {
            case LogLevel.Trace:
                return (...data: unknown[]) => {
                    console.groupCollapsed(...data);
                    console.trace();
                    console.groupEnd();
                };
            case LogLevel.Debug:
            case LogLevel.Info:
                return console.info;
            case LogLevel.Warn:
                return console.warn;
            case LogLevel.Error:
                return console.error;
        }
        return console.log;
        /*eslint-enable*/
    }

    private prefixByLevel(level: LogLevel): string {
        switch (level) {
            case LogLevel.Trace:
                return "TRACE";
            case LogLevel.Debug:
                return "DEBUG";
            case LogLevel.Info:
                return "INFO";
            case LogLevel.Warn:
                return "WARN";
            case LogLevel.Error:
                return "ERROR";
        }
        return "???";
    }

    public debug(message: string, data: object = {}) {
        this.print(LogLevel.Debug, message, data);
    }

    public info(message: string, data: object = {}) {
        this.print(LogLevel.Info, message, data);
    }

    public warn(message: string, data: object = {}) {
        this.print(LogLevel.Warn, message, data);
    }

    public error(message: string, data: object = {}) {
        this.print(LogLevel.Error, message, data);
    }

    public fatal(message: string, data: object = {}) {
        this.print(LogLevel.Fatal, message, data);
    }
}

const log = new Logger();
export default log;
