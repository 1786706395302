/**
 * @deprecated Use Cache Storage instead of LocalStorage, keep this file as backup to localstorage
 */

import { store } from "@src/store";
import log from "@src/utils/logger";
import { RequestMethod } from "@src/utils/request";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

const cacheTTL = 10;
const internalStorage = localStorage;
const storageIdentifier = "cinnamon.cache";

interface Storage {
    [identifier: string]: CacheEntry;
}

interface CacheEntry {
    expires: number;
    data: unknown;
}

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
const createCacheIdentifier = (userId: string | number, method: RequestMethod, url: string): string =>
    `request::${userId}::${method.toLowerCase()}::${url}`;

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
const storage = (): Storage => {
    return JSON.parse(internalStorage.getItem(storageIdentifier) ?? "{}") as Storage;
};

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
const setInStorage = (identifier: string, data: CacheEntry) => {
    const cache = storage();

    janitorOldCaches(cache);

    internalStorage.setItem(storageIdentifier, JSON.stringify({ ...cache, [identifier]: data }));
};

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
export const hasCache = (cacheIdentifier: string): boolean => {
    const cache = storage();

    if (!(cacheIdentifier in cache)) {
        return false;
    }

    if (cache[cacheIdentifier].expires > Date.now()) {
        return true;
    } else {
        delete cache[cacheIdentifier];
        internalStorage.setItem(storageIdentifier, JSON.stringify(cache));
    }

    return false;
};

const janitorThrottleTime = 5 * MINUTE;
let lastJanitorRun = 0;

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
const janitorOldCaches = (cache: Storage) => {
    if (Date.now() - lastJanitorRun > janitorThrottleTime) {
        return;
    }

    lastJanitorRun = Date.now();

    Object.keys(cache)
        .filter(key => cache[key].expires < Date.now())
        .forEach(key => delete cache[key]);
};

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
export const setCache = <Type>(cacheIdentifier: string, data: Type, ttl: number = cacheTTL) =>
    setInStorage(cacheIdentifier, { data, expires: Date.now() + ttl });

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
export const getCache = <Type>(cacheIdentifier: string): Type | null => {
    const cache = storage();

    if (!hasCache(cacheIdentifier)) {
        return null;
    }

    return cache[cacheIdentifier].data as Type;
};

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
export const removeCache = (cacheIdentifier: string) => {
    const cache = storage();

    // Remove all cache entries that start with the cache identifier. This is necessary because the cache holds
    // the same entity multiple times with different cache identifiers. The cache identifier is based on the
    // request parameters. So if a request for a particular entity is made it will have a different cache than
    // if the same entity is returned through a list query.
    Object.keys(cache)
        .filter(key => key.startsWith(cacheIdentifier))
        .forEach(key => delete cache[key]);

    internalStorage.setItem(storageIdentifier, JSON.stringify(cache));
};

/**
 * @deprecated Use Cache Storage instead of LocalStorage
 */
export const deleteCache = (method: RequestMethod, url: string) => {
    const state = store.getState();
    const ident = createCacheIdentifier(state.profile.data.id, method, url);
    removeCache(ident);
};

export const checkLocalStorageUsage = (): string => {
    const currentSize = JSON.stringify(localStorage).length / (1024 * 1024); // Convert to megabytes
    const maxSize = 5; // 5MB

    const percentageUsed = (currentSize / maxSize) * 100;

    return `Local Storage Usage: ${currentSize.toFixed(2)} MB out of ${maxSize} MB (${percentageUsed.toFixed(2)}%)`;
};

export const deleteLocalStorage = () => {
    log.debug("Delete local storage");
    localStorage.clear();
};
