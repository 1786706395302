import { CreditorDocument, DebitorDocument } from "@src/api/documents";
import {
    CreditorDocument as NotificationCreditorDocument,
    DebitorDocument as NotificationDebitorDocument,
} from "@src/features/notification/notification-slice";
import i18n from "@src/i18n";

export const debitorInternalPO = (document: NotificationDebitorDocument | DebitorDocument) =>
    i18n.t(`terms.documentTypes.${document.type}`, "n.n.") +
    " " +
    `${document.documentYear.toString().substring(2)}-` +
    `${document.currentNo}-` +
    `${document.company.customerNo.toString().padStart(3, "0")}-` +
    `${document.job?.number.toString().padStart(3, "0") ?? ""}-` +
    `${document.businessArea.shortName}`;

export const creditorInternalPO = (document: NotificationCreditorDocument | CreditorDocument) =>
    i18n.t(`terms.documentTypes.${document.type}`, "n.n.") + " " + document.currentNo + " | " + document.po;
