import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentListFilterFormInputs } from "@src/components/forms/debitors-creditors/DocumentListFilterForm";
import { disabledQuickFilters } from "@src/components/forms/debitors-creditors/DocumentQuickFilterWrapper";
import { RootState } from "@src/store";

export type QuickDocumentFilters = { [K in keyof DocumentListFilterFormInputs]: boolean };

interface InitialStateData {
    data: QuickDocumentFilters;
}

const initialState: InitialStateData = {
    data: {
        approvalStatus: false,
        businessAreas: false,
        cId: false,
        closed: false,
        company: false,
        currentNo: false,
        documentDate: false,
        documentYear: false,
        freeText: false,
        fromDate: false,
        hasFollowupOffer: false,
        hasUnfinishedServices: false,
        job: false,
        jobNo: false,
        paginateNo: false,
        paymentMethod: false,
        paymentStatus: false,
        po: false,
        receiptDate: false,
        runtimeStart: false,
        runtimeStop: false,
        status: false,
        toDate: false,
        type: false,
    },
};

export const quickDocumentFiltersSlice = createSlice({
    initialState,
    name: "quickDocumentFilters",
    reducers: {
        resetQuickDocumentFilters: state => {
            state.data = initialState.data;
        },
        setQuickDocumentFilters: (
            state,
            action: PayloadAction<{
                input: keyof QuickDocumentFilters;
                value: boolean;
            }>,
        ) => {
            if (disabledQuickFilters.includes(action.payload.input)) {
                return;
            }

            state.data = { ...state.data, ...{ [action.payload.input]: action.payload.value } };
        },
    },
});

export const { setQuickDocumentFilters, resetQuickDocumentFilters } = quickDocumentFiltersSlice.actions;

export const selectQuickDocumentFilters = (state: RootState) => state.quickDocumentFilters.data;

export default quickDocumentFiltersSlice.reducer;
