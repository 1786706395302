import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

interface CraneIconProps extends SvgIconProps {
    animate?: boolean;
}

const CraneIcon: React.FC<CraneIconProps> = (props: CraneIconProps): JSX.Element => {
    return (
        <>
            <style>
                {`
                    @keyframes driveCrane {
                        0% {
                            transform: rotate3d(0, 1, 0, 0deg);
                        }
                       
                        50% {
                            transform: rotate3d(0, 1, 0, 180deg);
                        }
                        
                        100% {
                            transform: rotate3d(0, 1, 0, 0deg);
                        }
                        }
                    `}
            </style>

            <SvgIcon {...props}>
                <svg
                    fill={props.fill}
                    height="800px"
                    id="Capa_1"
                    version="1.1"
                    viewBox="0 0 792.737 792.737"
                    width="800px"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <g>
                            <path
                                d="M693.645,129.306h65.803c0,0,12.788-50.869-44.475-52.988H587.598c0-3.878-2.517-7.404-6.458-8.282L278.042,0.209
			c-4.57-0.908-9.11,1.871-10.136,6.427c-0.133,0.631-0.033,1.24-0.033,1.854h-6.588c0-1.223-0.2-2.465-0.764-3.659
			c-2.038-4.239-7.103-6.026-11.29-3.99L58.467,91.983c-4.125,1.968-5.861,6.808-4.11,10.944l-21.923,0.381
			c0,0-10.599,25.998,82.662,25.998h105.979v61.469h139.894v-61.469h315.719v409.36c0,0.712,0.234,1.343,0.398,2.005
			c-17.238,3.728-30.204,19.038-30.204,37.393c0,18.182,12.783,33.381,29.806,37.258v25.414c0,2.934,1.508,5.65,4.005,7.207
			l13.912,8.66c1.458,1.737,2.256,3.906,2.256,6.207c0,2.588-0.998,5.037-2.818,6.854c-3.771,3.743-9.897,3.762-13.679-0.014
			c-3.308-3.312-8.676-3.312-11.987,0c-3.312,3.313-3.312,8.674,0,11.986c5.186,5.201,12.004,7.786,18.828,7.786
			c6.808,0,13.627-2.585,18.83-7.771c5.033-5.051,7.78-11.727,7.78-18.846c0-7.118-2.764-13.79-7.798-18.812
			c-0.454-0.462-0.963-0.858-1.511-1.204l-10.861-6.76v-20.715c17.022-3.877,29.812-19.077,29.812-37.258
			c0-18.354-12.97-33.665-30.209-37.393c0.165-0.662,0.397-1.295,0.397-2.007V129.306L693.645,129.306z M244.391,99.633
			l-168.733,2.933L251.96,18.332L244.391,99.633z M341.893,76.318l-55.11,26.99l-9.601-85.893l263.257,58.902L341.893,76.318
			L341.893,76.318z M706.497,578.063c0,11.756-9.574,21.326-21.332,21.326c-11.755,0-21.325-9.57-21.325-21.326
			c0-11.76,9.57-21.33,21.325-21.33C696.922,556.733,706.497,566.304,706.497,578.063z"
                                style={{
                                    animation: props.animate ? "driveCrane 10s linear infinite" : "none",
                                    transformOrigin: "300px 0px",
                                }}
                            />
                            <rect
                                height="140.816"
                                width="135.652"
                                x="191.401"
                                y="197.134"
                            />
                            <rect
                                height="328.867"
                                width="89.021"
                                x="214.717"
                                y="352.112"
                            />
                            <polygon points="363.086,792.737 303.738,757.583 303.738,702.949 214.717,702.949 214.717,747.843 157.487,792.737 		" />
                        </g>
                    </g>
                </svg>
            </SvgIcon>
        </>
    );
};

export default CraneIcon;
