import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const SiteNotFound = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return <h2>{t("pages.404.not-found", { path: location.pathname })}</h2>;
};

export default SiteNotFound;
