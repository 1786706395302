import { useStateWithDataFetcher } from "@src/hooks/data-fetcher";
import { get } from "@src/utils/request";
import { createContext, FC, ReactNode } from "react";

const DEFAULT_WEBSOCKET_URL = `wss://${document.location.hostname}/wss2/`;

// Context which holds the websocket URL for the connected finance desk instance. See the WebsocketURLProvider for
// more information.
const FDWebsocketContext = createContext<string>(DEFAULT_WEBSOCKET_URL);

type Props = {
    children?: ReactNode;
};

interface WebsocketConfig {
    host: string;
    port: string;
}

/**
 * This component provides the websocket URL to the application. It fetches the websocket URL from the backend and
 * provides it to the children components. The websocket URL is stored in the FDWebsocketContext.
 *
 * @param children
 * @constructor
 */
const WebsocketURLProvider: FC<Props> = ({ children }: Props): JSX.Element => {
    const [socketUrl] = useStateWithDataFetcher<string>(DEFAULT_WEBSOCKET_URL, async () => {
        const config = await get<WebsocketConfig>("/internalApi/websocket-config", {
            cache: true,
        });

        // The websocket setup in the local development environment is different from the production environment.
        // In production the websocket is served from the same domain as the application, but in development it is
        // served from a separate docker container with a different port than the application.
        if (document.location.hostname === "localhost") {
            return `ws://${config.host}:${config.port}`;
        } else {
            return DEFAULT_WEBSOCKET_URL;
        }
    });

    return <FDWebsocketContext.Provider value={socketUrl}>{children}</FDWebsocketContext.Provider>;
};

export { WebsocketURLProvider, FDWebsocketContext };
