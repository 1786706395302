interface Args {
    [key: string]: unknown;
}

const argsBuilder = (args: Args): string => {
    const newArgs: Args = {};

    const stringify = (value: unknown): string => {
        if (Array.isArray(value)) {
            return value.map(v => stringify(v)).join(",");
        }

        if (typeof value === "object") {
            return JSON.stringify(value);
        }

        return `${value}`;
    };

    for (const [key, value] of Object.entries(args)) {
        if (value === null || value === undefined) {
            continue;
        }
        if ((Array.isArray(value) || typeof value === "string") && value.length === 0) {
            continue;
        }
        newArgs[key] = stringify(value);
    }

    if (Object.keys(newArgs).length === 0) {
        return "";
    }

    return `?${Object.entries(newArgs)
        .map(([key, value]) => {
            value = encodeURIComponent(value as string);
            return `${key}=${value}`;
        })
        .join("&")}`;
};

export default argsBuilder;
