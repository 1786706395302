import { GRID_CHECKBOX_SELECTION_FIELD, GridInitialState } from "@mui/x-data-grid-premium";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreditorDocumentType, DebitorDocumentType } from "@src/api/documents";
import { RootState } from "@src/store";

type PickedGridInitialState = Pick<GridInitialState, "columns" | "pinnedColumns">;

export interface DataGridsSavedState {
    businessAreas: PickedGridInitialState;
    creditorDocs: {
        [key: string]: PickedGridInitialState;
    };
    creditorsList: PickedGridInitialState;
    debitorDocs: {
        [key: string]: PickedGridInitialState;
    };
    debitorsList: PickedGridInitialState;
    jobsList: PickedGridInitialState;
    serialInvoicesList: PickedGridInitialState;
    outboxList: PickedGridInitialState;
    creditorDiscountsList: PickedGridInitialState;
    productCategoriesList: PickedGridInitialState;
    productsList: PickedGridInitialState;
    textSnippetList: PickedGridInitialState;
    paymentsList: PickedGridInitialState;
    statisticsList: PickedGridInitialState;
    vatsList: PickedGridInitialState;
    zTermsList: PickedGridInitialState;
    rolesList: PickedGridInitialState;
    teamsList: PickedGridInitialState;
    apiUsersList: PickedGridInitialState;
    usersList: PickedGridInitialState;
}

export const defaultPinnedColumnsForDocumentLists = {
    left: [GRID_CHECKBOX_SELECTION_FIELD],
    right: ["actions"],
};

const initialState: DataGridsSavedState = {
    apiUsersList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    businessAreas: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    creditorDiscountsList: {},
    creditorDocs: {
        credit: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        credit_cancellation: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        creditor_order: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        default: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        invoice: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
    },
    creditorsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    debitorDocs: {
        default: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        invoice: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        offer: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        order: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        serial_invoice: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
        warning: {
            pinnedColumns: defaultPinnedColumnsForDocumentLists,
        },
    },
    debitorsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    jobsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    outboxList: {
        pinnedColumns: {
            right: ["print", "sent", "delete"],
        },
    },
    paymentsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    productCategoriesList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    productsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    rolesList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    serialInvoicesList: {
        pinnedColumns: {
            right: ["create", "remove"],
        },
    },
    statisticsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    teamsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    textSnippetList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    usersList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    vatsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
    zTermsList: {
        pinnedColumns: {
            right: ["actions"],
        },
    },
};

const normalizePayloadData = (gridState: GridInitialState): Pick<GridInitialState, "columns" | "pinnedColumns"> => ({
    columns: gridState.columns,
    pinnedColumns: gridState.pinnedColumns,
});

export const dataGridsSavedState = createSlice({
    initialState,
    name: "dataGridsSavedState",
    reducers: {
        resetDataGridsSavedState: () => initialState,
        setApiUsersListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.apiUsersList = normalizePayloadData(action.payload);
        },
        setBusinessAreasDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.businessAreas = normalizePayloadData(action.payload);
        },
        setCreditorDiscountsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.creditorDiscountsList = normalizePayloadData(action.payload);
        },
        setCreditorDocsDataGridState: (
            state,
            action: PayloadAction<{
                state: GridInitialState;
                docType: CreditorDocumentType | undefined;
            }>,
        ) => {
            if (!action.payload.docType) {
                return;
            }

            state.creditorDocs[action.payload.docType] = normalizePayloadData(action.payload.state);
        },
        setCreditorsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.creditorsList = normalizePayloadData(action.payload);
        },
        setDebitorDocsDataGridState: (
            state,
            action: PayloadAction<{
                state: GridInitialState;
                docType: DebitorDocumentType | undefined;
            }>,
        ) => {
            if (!action.payload.docType) {
                return;
            }

            state.debitorDocs[action.payload.docType] = normalizePayloadData(action.payload.state);
        },
        setDebitorsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.debitorsList = normalizePayloadData(action.payload);
        },
        setJobsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.jobsList = normalizePayloadData(action.payload);
        },
        setOutboxListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.outboxList = normalizePayloadData(action.payload);
        },
        setPaymentsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.paymentsList = normalizePayloadData(action.payload);
        },
        setProductCategoriesListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.productCategoriesList = normalizePayloadData(action.payload);
        },
        setProductsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.productsList = normalizePayloadData(action.payload);
        },
        setRolesListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.rolesList = normalizePayloadData(action.payload);
        },
        setSerialInvoicesListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.serialInvoicesList = normalizePayloadData(action.payload);
        },
        setStatisticsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.statisticsList = normalizePayloadData(action.payload);
        },
        setTeamsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.teamsList = normalizePayloadData(action.payload);
        },
        setTextSnippetListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.textSnippetList = normalizePayloadData(action.payload);
        },
        setUsersListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.usersList = normalizePayloadData(action.payload);
        },
        setVatsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.vatsList = normalizePayloadData(action.payload);
        },
        setZTermsListDataGridState: (state, action: PayloadAction<GridInitialState>) => {
            state.zTermsList = normalizePayloadData(action.payload);
        },
    },
});

export const {
    setBusinessAreasDataGridState,
    setCreditorDocsDataGridState,
    setCreditorsListDataGridState,
    setDebitorDocsDataGridState,
    setDebitorsListDataGridState,
    setJobsListDataGridState,
    setSerialInvoicesListDataGridState,
    setOutboxListDataGridState,
    setCreditorDiscountsListDataGridState,
    setProductCategoriesListDataGridState,
    setProductsListDataGridState,
    setTextSnippetListDataGridState,
    setPaymentsListDataGridState,
    setStatisticsListDataGridState,
    setVatsListDataGridState,
    setZTermsListDataGridState,
    setRolesListDataGridState,
    setTeamsListDataGridState,
    setApiUsersListDataGridState,
    setUsersListDataGridState,
    resetDataGridsSavedState,
} = dataGridsSavedState.actions;

export const selectBusinessAreasSavedState = (state: RootState) => state.dataGridsSavedState.businessAreas;
export const selectCreditorDocsSavedState = (state: RootState) => state.dataGridsSavedState.creditorDocs;
export const selectCreditorsListSavedState = (state: RootState) => state.dataGridsSavedState.creditorsList;
export const selectDebitorDocsSavedState = (state: RootState) => state.dataGridsSavedState.debitorDocs;
export const selectDebitorsListSavedState = (state: RootState) => state.dataGridsSavedState.debitorsList;
export const selectJobsListSavedState = (state: RootState) => state.dataGridsSavedState.jobsList;
export const selectSerialInvoicesListSavedState = (state: RootState) => state.dataGridsSavedState.serialInvoicesList;
export const selectOutboxListSavedState = (state: RootState) => state.dataGridsSavedState.outboxList;
export const selectCreditorDiscountsListSavedState = (state: RootState) =>
    state.dataGridsSavedState.creditorDiscountsList;
export const selectProductCategoriesListSavedState = (state: RootState) =>
    state.dataGridsSavedState.productCategoriesList;
export const selectProductsListSavedState = (state: RootState) => state.dataGridsSavedState.productsList;
export const selectTextSnippetListSavedState = (state: RootState) => state.dataGridsSavedState.textSnippetList;
export const selectPaymentsListSavedState = (state: RootState) => state.dataGridsSavedState.paymentsList;
export const selectStatisticsListSavedState = (state: RootState) => state.dataGridsSavedState.statisticsList;
export const selectVatsListSavedState = (state: RootState) => state.dataGridsSavedState.vatsList;
export const selectZTermsListSavedState = (state: RootState) => state.dataGridsSavedState.zTermsList;
export const selectRolesListSavedState = (state: RootState) => state.dataGridsSavedState.rolesList;
export const selectTeamsListSavedState = (state: RootState) => state.dataGridsSavedState.teamsList;
export const selectApiUsersListSavedState = (state: RootState) => state.dataGridsSavedState.apiUsersList;
export const selectUsersListSavedState = (state: RootState) => state.dataGridsSavedState.usersList;

export default dataGridsSavedState.reducer;
