import log from "@src/utils/logger";

const CACHE_NAME = "cache-v1";
const EXPIRES_HEADER = "x-expires";

export const getCacheStorage = async <Type>(url: string): Promise<Type | null> => {
    try {
        const cache = await caches.open(CACHE_NAME);
        const response = await cache.match(url);

        if (!response) {
            return Promise.resolve(null);
        }

        // get the expiration date from the response headers
        const expiresHeader = response.headers.get(EXPIRES_HEADER);

        // calculate the timestamp when the data expires
        const expireTimestamp = Number(expiresHeader || 0);

        // if the data is still valid, return it
        if (expireTimestamp > Date.now()) {
            log.debug(`Data valid: ${url}`);
            const data = await response.json();
            return Promise.resolve(data);
        }

        log.debug(`Data expired: ${url}`);
        // if the data has no expiration date or if the data are expired, return null
        return Promise.resolve(null);
    } catch (error) {
        log.error(`Error fetching data from cache: ${error}`);
        return Promise.resolve(null);
    }
};

export const updateCacheStorage = async (url: string, resp: Response, ttl: number) => {
    try {
        log.debug(`add data in cache: ${url}`);
        const cache = await caches.open(CACHE_NAME);
        resp.headers.append(EXPIRES_HEADER, (Date.now() + ttl).toString());
        await cache.put(url, resp);
    } catch (error) {
        log.error(`Error updating data in storage: ${error}`);
    }
};

export const deleteRelatedItemsFromCacheStorage = async (url: string) => {
    try {
        log.debug(`Delete all by key from: ${url}`);
        const cache = await caches.open(CACHE_NAME);
        cache
            .keys()
            .then(keys => keys.filter(key => key.url.includes(url)))
            .then(keys => keys.forEach(key => cache.delete(key)));
    } catch (error) {
        log.error(`Error deleting all data by key in storage: ${error}`);
    }
};

export const getCacheStorageCapacity = async () => {
    try {
        const quota = await navigator.storage.estimate();
        if (quota && quota.quota && quota.usage) {
            const currentSize = quota.usage / (1024 * 1024);
            const maxSize = quota.quota / (1024 * 1024);
            const percentageUsed = Math.round((currentSize / maxSize) * 100);

            return Promise.resolve(
                `Cache Storage Usage: ${currentSize.toFixed(2)} MB out of ${maxSize.toFixed(
                    2,
                )} MB (${percentageUsed.toFixed(2)}%)`,
            );
        }

        return Promise.resolve("Error getting cache storage capacity");
    } catch (error) {
        log.error(`Error getting cache storage capacity: ${error}`);
        return Promise.resolve("Error getting cache storage capacity");
    }
};

export const deleteCacheStorage = async () => {
    try {
        log.debug("Delete cache storage");
        await caches.delete(CACHE_NAME);
    } catch (error) {
        log.error(`Error deleting cache storage: ${error}`);
    }
};
