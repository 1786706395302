import { deDE, enUS } from "@mui/material/locale";
import merge from "@mui/system/merge";
import { deDE as dataGridDeDE, enUS as dataGridEnUS } from "@mui/x-data-grid/locales";
import { deDE as datePickerDE, enUS as datePickerUS } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import i18n, { CallbackError } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin as translationCheck } from "translation-check";

import log from "./utils/logger";

export enum Language {
    English = "en",
    German = "de",
}

export enum Currency {
    EUR = "EUR",
    USD = "USD",
}

export const currentLanguage = (): string => i18n.resolvedLanguage ?? Language.English;

export const DatePickerLocale = (): {
    mask: string;
    format: string;
} => {
    switch (currentLanguage()) {
        case Language.German: {
            return {
                format: "DD.MM.YYYY",
                mask: "__.__.____",
            };
        }
        case Language.English:
        default:
            return {
                format: "DD/MM/YYYY",
                mask: "__/__/____",
            };
    }
};

export const muiLocaleComponents = () => {
    switch (currentLanguage()) {
        case Language.German:
            return [deDE, dataGridDeDE];
        case Language.English:
        default:
            return [enUS, dataGridEnUS];
    }
};

export const muiDatePickerAdapterLocale = (): string => {
    switch (currentLanguage()) {
        case Language.German: {
            return Language.German;
        }
        case Language.English:
        default:
            return Language.English;
    }
};

export const generateCurrencySymbol = (currency: string): string => {
    switch (currency) {
        case Currency.EUR:
            return " €";
        case Currency.USD:
            return " $";
        default:
            return ` ${currency}`;
    }
};

export const muiDatePickerLocalizationProviderLocaleText = () => {
    switch (currentLanguage()) {
        case Language.German:
            return datePickerDE.components.MuiLocalizationProvider.defaultProps.localeText;
        case Language.English:
        default:
            return datePickerUS.components.MuiLocalizationProvider.defaultProps.localeText;
    }
};

export const formatPrice = (price: number | string | null | undefined, currency: Currency = Currency.EUR) => {
    if (!price) {
        price = 0;
    }

    if (typeof price === "string") {
        price = parseFloat(price);
    }

    const formatter = new Intl.NumberFormat(currentLanguage(), {
        currency,
        style: "currency",
    });

    return formatter.format(price);
};

export const formatPercent = (percent: number | string | undefined | null) => {
    if (!percent) {
        percent = 0;
    }

    if (typeof percent === "string") {
        percent = parseFloat(percent);
    }

    const formatter = new Intl.NumberFormat(currentLanguage(), {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    return formatter.format(percent) + " %";
};

export const formatNumericMonth = (num: number) => {
    const formatter = new Intl.DateTimeFormat(currentLanguage(), { month: "long" });
    return formatter.format(new Date(num.toString()));
};

/**
 * Formats a date string. The format can be specified using the Intl.DateTimeFormatOptions. The default is DATE.
 *
 * @param dateString
 * @param format
 * @param asUTC
 */
export const formatDate = (
    dateString: Date | string | null | undefined,
    format: "DATE" | "DATE_TIME" | "TIME" = "DATE",
    asUTC = false,
): string => {
    if (!dateString) {
        return "";
    }

    let intlFormat: Intl.DateTimeFormatOptions;
    switch (format) {
        case "DATE_TIME": {
            intlFormat = {
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                month: "short",
                second: "numeric",
                year: "numeric",
            };
            break;
        }
        case "TIME": {
            intlFormat = {
                hour: "numeric",
                minute: "numeric",
            };
            break;
        }
        case "DATE":
        default: {
            intlFormat = {
                day: "numeric",
                month: "short",
                year: "numeric",
            };
        }
    }

    dayjs.locale(currentLanguage());
    dayjs.extend(utc);

    // Time coming from the server should be in UTC
    const date = asUTC ? dayjs.utc(dateString).local() : dayjs(dateString);

    if (!date.isValid()) {
        log.debug("Invalid date", { dateString });
        return "";
    }

    return new Intl.DateTimeFormat(currentLanguage(), intlFormat).format(date.toDate());
};

const dynamicallyImportLanguageFiles = resourcesToBackend(async (language, _namespace, callback) => {
    try {
        const translations = await import(`@src/translations/${language}.json`);
        const customerBasedTranslations = await import(`@src/translations/${CINNAMON_VENDOR}/${language}.json`).catch(
            () => ({ default: () => ({}) }),
        );

        const mergedTranslations = merge(translations.default, customerBasedTranslations.default);

        callback(null, { ...mergedTranslations });
    } catch (err) {
        log.error("Error while loading translation files", { err });
        callback(err as CallbackError, null);
    }
});

i18n.use(dynamicallyImportLanguageFiles)
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(translationCheck)
    .init({
        debug: CINNAMON_DEVMODE,
        detection: {
            lookupLocalStorage: "language",
            order: ["localStorage", "navigator"],
        },
        fallbackLng: Language.German, // TODO: revert this to "en" eventually...
        interpolation: {
            escapeValue: false,
        },
        load: "languageOnly",
        supportedLngs: [Language.English, Language.German],
    });

export default i18n;
