import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

interface PendingIndicatorProps {
    open: boolean;
    transparent?: boolean;
}

const PendingIndicator: React.FC<PendingIndicatorProps> = ({ transparent = true }) => {
    const theme = useTheme();

    if (!open) {
        return null;
    }

    return (
        <Box
            sx={{
                ...(transparent && { minHeight: 250 }),

                ...(!transparent && { backgroundColor: theme.palette.background.default }),

                // center content

                flexGrow: 1,

                left: "50vw",

                // justifyContent: "center",
                position: "fixed",

                // display box above content pane
                ...(!transparent && { position: "fixed" }),

                top: "50vh",
                zIndex: theme.zIndex.drawer,
            }}
        >
            <CircularProgress
                color="primary"
                size={100}
            />
        </Box>
    );
};

export default React.memo(PendingIndicator);
