import { Company } from "@src/api/companies";
import { SymfonyFields } from "@src/api/interfaces/Symfony";
import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import argsBuilder from "@src/utils/args-builder";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";
import React from "react";

export interface Team extends SymfonyFields {
    id: CinnamonID;
    name: string;
}

export const TeamsContext = React.createContext<Team[]>([]);

export const fetchTeams = async (sort?: "name"): Promise<Team[]> => {
    const args = argsBuilder({ sort });
    const res = await get<ApiResponse<Company>>(`/internalApi/teams${args}`, { cache: true, ttl: 5 * MINUTE });
    return res.items;
};

export const fetchTeam = async (id: CinnamonID): Promise<Team> => await get<Team>(`/internalApi/teams/${id}`);

export const createTeam = async (team: Team) => {
    await post("/internalApi/teams", { body: team });
    await burstTeamsCache();
};

export const updateTeam = async (id: CinnamonID, team: Team) => {
    await put(`/internalApi/teams/${id}`, { body: team });
    await burstTeamsCache();
};

export const burstTeamsCache = async () => deleteRelatedItemsFromCacheStorage("/internalApi/teams");
