import { createTheme, Theme } from "@mui/material";
import Vendor from "@src/features/customization/Vendor";
import { muiLocaleComponents } from "@src/i18n";

import A1Theme from "../customization/A1Theme";
import DefaultTheme from "./DefaultTheme";

export const drawerWidth = 240;
export const closedDrawerWidth = 96 - 16 - 8;

const vendorTheme = (): Theme => {
    let theme = DefaultTheme;

    switch (CINNAMON_VENDOR) {
        case Vendor.A1:
            theme = A1Theme;
            break;
        case Vendor.Default:
            theme = DefaultTheme;
            break;
    }

    return createTheme(theme, ...muiLocaleComponents());
};

const theme = vendorTheme();

export default theme;
