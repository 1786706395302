import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/store";

export interface MaintenanceData {
    message?: string | null;
    scheduled: boolean;
    planned?: string | null;
    duration?: number | null;
    active: boolean;
}

interface MaintenanceState {
    data: MaintenanceData;
}

export interface MaintenanceRequest {
    planned?: string | null;
    duration?: number | null;
}

const initialState: MaintenanceState = {
    data: {
        active: false,
        duration: null,
        message: null,
        planned: null,
        scheduled: false,
    },
};

export const maintenanceSlice = createSlice({
    initialState,
    name: "maintenance",
    reducers: {
        disableMaintenance: state => {
            state.data.active = false;
        },
        enableMaintenance: state => {
            state.data.active = true;
        },
        setMaintenance: (state, action: PayloadAction<MaintenanceRequest>) => {
            state.data.duration = action.payload.duration ?? null;
            state.data.planned = action.payload.planned ?? null;
            state.data.scheduled = action.payload.planned !== null;
        },
    },
});

export const { setMaintenance, enableMaintenance, disableMaintenance } = maintenanceSlice.actions;

export const selectMaintenance = (state: RootState) => state.maintenance.data;

export default maintenanceSlice.reducer;
