import { AppDispatch } from "@src/store";
import { get } from "@src/utils/request";

import { NotificationsRequest, updateNotifications } from "./notification-slice";

const updateNotificationsTask = async (dispatch: AppDispatch) => {
    const notifications = await get<NotificationsRequest>("/internalApi/notifications");
    dispatch(updateNotifications(notifications));
};

export default updateNotificationsTask;
