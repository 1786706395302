import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

interface ExcavatorIconProps extends SvgIconProps {
    animate?: boolean;
}

const ExcavatorIcon: React.FC<ExcavatorIconProps> = (props: ExcavatorIconProps): JSX.Element => {
    return (
        <>
            <style>
                {`
                @keyframes moveExcavator {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(23deg);
                  }
                }
                `}
            </style>
            <SvgIcon {...props}>
                <svg
                    fill={props.fill}
                    id="Capa_1"
                    version="1.1"
                    viewBox={props.animate ? "0 0 800 1800" : "0 0 800 800"}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <g>
                            <path
                                d="M293.054,660.519c8.189,0,14.859-6.67,14.859-14.863c0-8.201-6.67-14.87-14.859-14.87c-8.211,0-14.881,6.669-14.881,14.87
			C278.172,653.849,284.842,660.519,293.054,660.519z"
                            />
                            <path
                                d="M193.239,658.753c7.229,0,13.115-5.872,13.115-13.1c0-7.23-5.885-13.104-13.115-13.104
			c-7.216,0-13.091,5.873-13.091,13.104C180.147,652.881,186.022,658.753,193.239,658.753z"
                            />
                            <path
                                d="M397.102,658.753c7.232,0,13.117-5.872,13.117-13.1c0-7.23-5.885-13.104-13.117-13.104c-7.217,0-13.09,5.873-13.09,13.104
			C384.011,652.881,389.885,658.753,397.102,658.753z"
                            />
                            <path
                                d="M83.519,658.753c7.229,0,13.103-5.872,13.103-13.1c0-7.23-5.874-13.104-13.103-13.104
			c-7.218,0-13.091,5.873-13.091,13.104C70.428,652.881,76.302,658.753,83.519,658.753z"
                            />
                            <path
                                d="M530.365,699.805v-3.587c25.098-3.146,44.596-24.623,44.596-50.562c0-25.947-19.498-47.425-44.596-50.57v-3.58H44.597
			v3.58C19.485,598.232,0,619.708,0,645.656c0,25.939,19.485,47.418,44.597,50.562v3.587H530.365z M83.519,619.808
			c12.042,0,22.095,8.312,24.957,19.476h59.815c2.848-11.164,12.9-19.476,24.947-19.476c12.042,0,22.1,8.312,24.96,19.476h48.056
			c2.886-12.157,13.774-21.24,26.799-21.24c13.017,0,23.89,9.083,26.793,21.24h52.309c2.847-11.164,12.901-19.476,24.947-19.476
			c12.043,0,22.1,8.312,24.959,19.476h53.084c2.857-11.164,12.916-19.476,24.957-19.476c14.25,0,25.834,11.598,25.834,25.848
			c0,14.24-11.584,25.839-25.834,25.839c-12.041,0-22.1-8.311-24.957-19.47h-53.084c-2.857,11.159-12.916,19.47-24.959,19.47
			c-12.044,0-22.099-8.311-24.946-19.47h-52.309c-2.903,12.154-13.776,21.236-26.793,21.236c-13.024,0-23.913-9.082-26.799-21.236
			h-48.056c-2.86,11.159-12.918,19.47-24.96,19.47c-12.047,0-22.099-8.311-24.947-19.47h-59.815
			c-2.862,11.159-12.915,19.47-24.957,19.47c-14.25,0-25.832-11.599-25.832-25.839C57.688,631.404,69.269,619.808,83.519,619.808z"
                            />
                            <path
                                d="M500.102,658.753c7.219,0,13.092-5.872,13.092-13.1c0-7.23-5.873-13.104-13.092-13.104
			c-7.229,0-13.113,5.873-13.113,13.104C486.988,652.881,492.873,658.753,500.102,658.753z"
                            />
                            <path
                                d="M486.988,434.757l-42.977-74.881H307.914v68.512l-75.208,3.187v-17.521l-100.339-1.593v15.928H96.622v45.113h136.084
			v18.593H96.622v58.292h390.366V434.757L486.988,434.757z M459.941,473.501H333.044v-91.074h95.043l31.854,45.537V473.501z"
                            />
                            <polygon points="426.492,269.119 557.096,170.375 557.096,114.628 397.102,210.189 333.044,340.79 426.492,340.79 		" />
                            <g
                                style={{
                                    animation: props.animate ? "moveExcavator 4s infinite ease-in-out alternate" : "",
                                    transformOrigin: "600px 132px",
                                }}
                            >
                                <polygon points="783.254,425.202 600.096,92.332 574.961,114.628 574.961,160.817 738.658,425.202 		" />
                                <path
                                    d="M749.809,455.126c1.068,92.65-86.006,95.26-86.006,95.26c176.812,69.446,119.451-95.26,119.451-95.26H749.809
			L749.809,455.126z"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </SvgIcon>
        </>
    );
};

export default ExcavatorIcon;
