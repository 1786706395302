import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "@src/store";
import log from "@src/utils/logger";
import md5 from "md5";

interface PendingState {
    queue: string[];
}

const initialState: PendingState = {
    queue: [],
};

export const pendingState = createSlice({
    initialState,
    name: "notification",
    reducers: {
        addToPendingQueue: (state, action: PayloadAction<string>) => {
            state.queue.push(action.payload);
            log.debug(`Added ${action.payload} to pending queue`);
        },
        removeFromPendingQueue: (state, action: PayloadAction<string>) => {
            state.queue = state.queue.filter(item => item !== action.payload);
            log.debug(`Removed ${action.payload} from pending queue`);
        },
    },
});

export const { addToPendingQueue, removeFromPendingQueue } = pendingState.actions;

export const selectIsPending = (state: RootState) => state.pending.queue.length > 0;

export const createPendingIdentifier = () => {
    return md5((Math.random() + 1).toString(36).substring(7));
};

export const pendingFunc = async <T>(dispatch: AppDispatch, func: () => Promise<T>) => {
    const ident = createPendingIdentifier();
    dispatch(addToPendingQueue(ident));
    const value = await func();
    dispatch(removeFromPendingQueue(ident));
    return value;
};

export default pendingState.reducer;
