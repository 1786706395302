import { createTheme } from "@mui/material";

import DefaultTheme from "../theme/DefaultTheme";

const A1Theme = createTheme(DefaultTheme, {
    palette: {
        background: {
            default: "#df00ff",
            main: "#df00ff",
            paper: "#df00ff",
        },
        primary: {
            main: "#0000FF", // TODO: add the actual color
        },
    },
});

export default A1Theme;
