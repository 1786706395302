import SchedulerTask from "@src/features/scheduler/SchedulerTask";
import { useAppDispatch } from "@src/hooks/redux";
import log from "@src/utils/logger";
import { useEffect, useState } from "react";

interface TaskExecutedThreshold {
    [name: string]: number;
}

interface SchedulerProps {
    tasks: SchedulerTask[];
}

const TIMEOUT = 1000;

const Scheduler = ({ tasks }: SchedulerProps) => {
    const [taskExecutedThreshold, setTaskExecutedThreshold] = useState<TaskExecutedThreshold>({});
    const dispatch = useAppDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            tasks.forEach(task => {
                const currentThreshold = taskExecutedThreshold[task.name];

                if (currentThreshold >= task.delay) {
                    log.debug("Run task: " + task.name);
                    task.fn(dispatch);

                    taskExecutedThreshold[task.name] = 0;
                    return;
                }

                taskExecutedThreshold[task.name] += TIMEOUT;
            });
            setTaskExecutedThreshold(taskExecutedThreshold);
        }, TIMEOUT);

        tasks.forEach(task => {
            taskExecutedThreshold[task.name] = 0;
        });

        tasks
            .filter(task => task.triggerInstantly)
            .forEach(task => {
                log.debug("Run task: " + task.name);
                task.fn(dispatch);
            });

        setTaskExecutedThreshold(taskExecutedThreshold);

        return () => clearInterval(interval);
    }, [dispatch, taskExecutedThreshold, tasks]);

    return null;
};

export default Scheduler;
