import { Box, Typography } from "@mui/material";
import theme from "@src/components/theme/theme";
import React from "react";
import { useTranslation } from "react-i18next";

const StagePreviewBar: React.FC = () => {
    const { t } = useTranslation();

    if (CINNAMON_IS_PREVIEW || CINNAMON_IS_STAGE) {
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.corporateColor.main,
                    height: "20px",
                    position: "fixed",
                    top: "0px",
                    width: "100%",
                    zIndex: theme => theme.zIndex.drawer + 2,
                }}
            >
                <Typography
                    sx={{
                        color: theme.palette.warning.contrastText,
                        pl: 2,
                        pt: "1px",
                        verticalAlign: "center",
                    }}
                >
                    {CINNAMON_IS_STAGE
                        ? t("system.stage-notification").toUpperCase()
                        : t("system.preview-notification").toUpperCase()}
                </Typography>
            </Box>
        );
    } else {
        return null;
    }
};

export default React.memo(StagePreviewBar);
