import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { UsersFormInputs } from "@src/components/forms/users/UsersForm";
import { User } from "@src/types/Users";
import { MINUTE } from "@src/utils/cache";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { delete_, get, post, put } from "@src/utils/request";

export const fetchUsers = async (): Promise<ApiResponse<User>> =>
    await get<ApiResponse<User>>("/internalApi/users", { cache: true, ttl: 5 * MINUTE });
export const fetchUser = async (id: CinnamonID): Promise<User> => await get<User>(`/internalApi/users/${id}`);

export const updateUser = async (userId: CinnamonID, body: UsersFormInputs): Promise<void> => {
    await put(`/internalApi/users/${userId}`, { body });
    burstApiUserCaches();
};

export const createUser = async (body: UsersFormInputs): Promise<void> => {
    await post("/internalApi/users", { body });
    burstApiUserCaches();
};

export const deleteUser = async (userId: CinnamonID): Promise<void> => {
    await delete_(`/internalApi/users/${userId}`);
    burstApiUserCaches();
};

export const resetUserPassword = async (user: User): Promise<void> => {
    await put(`/internalApi/users/${user.id}/password`, {
        body: user,
    });
};

export const burstApiUserCaches = () => deleteRelatedItemsFromCacheStorage("/internalApi/users");
