import {
    AccountBalance,
    Article,
    Badge,
    BusinessCenterSharp,
    BusinessOutlined,
    CategorySharp,
    Dataset,
    DatasetLinked,
    DocumentScanner,
    Event,
    Folder,
    FormatListNumberedSharp,
    Group,
    InsightsSharp,
    ManageAccountsSharp,
    Outbox,
    PercentOutlined,
    PieChartOutlineSharp,
    QuestionMark,
    Receipt,
    Repeat,
    SsidChartSharp,
    TrendingUpSharp,
    Work,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Box, Theme, Tooltip } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import log from "@src/utils/logger";
import * as React from "react";
import { NavLink } from "react-router-dom";

export interface MenuEntry {
    name: string;
    icon: string | null;
    link: string | null;
    items: MenuEntry[] | null;
    selected: boolean;
    unsupported: boolean;
}

export const createMenuStructure = (menuData: MenuEntry[], navigationOpen = false, theme: Theme) =>
    Array.isArray(menuData)
        ? menuData.map((menuEntry, index) =>
            menuEntry.link
                ? createMenuEntry(menuEntry, true, navigationOpen, theme, index)
                : createMenuSubMenuStructure(menuEntry, navigationOpen, theme, index),
        )
        : null;

export const createMenuEntry = (
    menuEntry: MenuEntry,
    topLevel = false,
    navigationOpen = false,
    theme: Theme,
    index: number,
) =>
    menuEntry.link ? (
        <Box key={index}>
            <ListItemButton
                key={`entry::${menuEntry.link}`}
                component={NavLink}
                selected={menuEntry.selected}
                sx={{
                    borderTop: topLevel ? `solid ${theme.palette.highlightGrey.main} 8px` : "0",
                    // 42px is read via developer tools from the browser when the menu is open and the
                    height: navigationOpen ? "initial" : "42px",
                    justifyContent: navigationOpen ? "initial" : "center",
                    mb: topLevel ? 0 : 0,
                    pl: topLevel ? 2 : navigationOpen ? 4 : 2,
                }}
                to={menuEntry.link}
            >
                <Tooltip
                    disableHoverListener={navigationOpen}
                    placement={"right"}
                    title={menuEntry.name}
                >
                    <ListItemIcon
                        sx={{
                            color: theme.palette.primary.main,
                            justifyContent: "center",
                            minWidth: 0,
                            mr: navigationOpen ? 1.5 : "auto",
                        }}
                    >
                        {iconByMenuEntry(menuEntry)}
                    </ListItemIcon>
                </Tooltip>

                <Tooltip
                    disableHoverListener={navigationOpen}
                    placement={"right"}
                    title={menuEntry.name}
                >
                    <ListItemText
                        primary={navigationOpen ? menuEntry.name : ""}
                        sx={{ textDecoration: menuEntry.unsupported ? "line-through" : undefined }}
                    />
                </Tooltip>
            </ListItemButton>
        </Box>
    ) : null;

const createMenuSubMenuStructure = (menuEntry: MenuEntry, navigationOpen = false, theme: Theme, index: number) =>
    menuEntry.items ? (
        <Box key={index}>
            <Box />
            <Tooltip
                disableHoverListener={navigationOpen}
                placement={"right"}
                title={menuEntry.name}
            >
                <ListSubheader
                    key={`subheader::${menuEntry.name}`}
                    component="div"
                    inset={navigationOpen}
                    sx={{
                        backgroundColor: theme.palette.highlightGrey.main,
                        border: 0,
                        height: "32px",
                        lineHeight: "32px",
                        pl: 2,
                    }}
                >
                    {navigationOpen ? menuEntry.name : `${menuEntry.name.substring(0, 3)}...`}
                </ListSubheader>
            </Tooltip>

            {menuEntry.items.map((entry, index) => createMenuEntry(entry, false, navigationOpen, theme, index))}
        </Box>
    ) : null;

const iconByMenuEntry = (menuEntry: MenuEntry) => {
    switch (menuEntry.link) {
        case "/dashboard":
            return <DashboardIcon />;
        case "/jobs":
            return <Work />;
        case "/debitors/document":
        case "/creditors/document":
            return <Folder />;
        case "/debitors/list":
        case "/creditors/list":
            return <BusinessOutlined />;
        case "/datev":
            return <Dataset />;
        case "/debitors/serialinvoices":
            return <Repeat />;
        case "/debitors/outbox":
            return <Outbox />;
        case "/debitors/due-dates":
            return <Event />;
        case "/settings/creditor-discounts":
            return <PercentOutlined />;
        case "/templates/snippets":
            return <DatasetLinked />;
        case "/products":
            return <Article />;
        case "/productcategories":
            return <CategorySharp />;
        case "/cashbox":
            return <Receipt />;
        case "/system/scans":
            return <DocumentScanner />;
        case "/payments":
            return <AccountBalance />;
        case "/statistics":
            return <TrendingUpSharp />;
        case "/statistics/annual_planing":
            return <InsightsSharp />;
        case "/statistics/unfinished_services":
            return <PieChartOutlineSharp />;
        case "/settings/business_areas":
            return <BusinessCenterSharp />;
        case "/settings/document-counter":
            return <FormatListNumberedSharp />;
        case "/settings/vats":
            return <SsidChartSharp />;
        case "/settings/zterms":
            return <DatasetLinked />;
        case "/system/users":
            return <ManageAccountsSharp />;
        case "/system/teams":
            return <Group />;
        case "/system/roles":
            return <Badge />;
    }

    if (menuEntry) {
        log.error(`No icon found for: ${menuEntry.link}`);
    }

    return <QuestionMark />;
};
