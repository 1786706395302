import { Box, Typography } from "@mui/material";
import CraneIcon from "@src/components/maintenance/Crane";
import ExcavatorIcon from "@src/components/maintenance/Excavator";
import TruckIcon from "@src/components/maintenance/Truck";
import { DEFAULT_APP_NAME } from "@src/constants";
import { MaintenanceData, selectMaintenance } from "@src/features/maintanance/maintenance-slice";
import updateMaintenanceModeTask from "@src/features/maintanance/maintenance-task";
import { useAppSelector } from "@src/hooks/redux";
import { AppDispatch, store } from "@src/store";
import log from "@src/utils/logger";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    children?: React.ReactNode;
};

const appName = CINNAMON_APPNAME ?? DEFAULT_APP_NAME;

export const checkIfMaintenanceIsOver = async (dispatch: AppDispatch) => {
    log.debug("SchedulerTask: checkIfMaintenanceIsOver");

    if (!store.getState().maintenance.data.active) {
        return;
    }

    await updateMaintenanceModeTask(dispatch);
};

const MaintenanceMode: React.FC<Props> = ({ children }: Props): React.JSX.Element => {
    const maintenanceState = useAppSelector<MaintenanceData>(selectMaintenance);

    const { t } = useTranslation();

    if (maintenanceState.active) {
        return (
            <>
                <style>
                    {`
                    @keyframes driveTruck {
                      0% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 0deg);
                      }
                      
                      45% {
                        transform: translate(calc(100vw - 450px), 0) rotate3d(0, 1, 0, 0deg);
                      }
                      55% {
                        transform: translate(calc(100vw - 450px), 0) rotate3d(0, 1, 0, 180deg);
                      }
                      
                      90% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 180deg);
                      }
                      
                      100% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 0deg);
                      }
                    }
                    
                    @keyframes driveExcavator {
                      0% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 0deg);
                      }
                      
                      45% {
                        transform: translate(calc(25vw + 150px), 10vh) rotate3d(0, 1, 0, 0deg);
                      }
                      55% {
                        transform: translate(calc(25vw + 150px), 10vh) rotate3d(0, 1, 0, 180deg);
                      }
                      
                      90% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 180deg);
                      }
                      
                      100% {
                        transform: translate(calc(0vw + 150px), 0) rotate3d(0, 1, 0, 0deg);
                      }
                    }
                    `}
                </style>
                <>
                    <Box
                        sx={{
                            backgroundColor: "warning.main",
                            height: "calc(100vh)",
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: "calc(100vw)",
                        }}
                    />
                    <Box
                        sx={{
                            left: "calc(50vw - 200px)",
                            position: "absolute",
                            top: "calc(50vh - 50px)",
                        }}
                    >
                        <Typography variant={"h1"}>{t("component.maintenance.title")}</Typography>
                        <Typography variant={"h2"}>{appName}</Typography>
                    </Box>

                    <ExcavatorIcon
                        animate={true}
                        sx={{
                            animation: "driveExcavator 30s infinite ease-in-out",
                            height: 300,
                            position: "absolute",
                            top: "calc(50vh - 300px)",
                            width: 300,
                        }}
                    />
                    <TruckIcon
                        sx={{
                            animation: "driveTruck 8s infinite ease-in-out",
                            height: 150,
                            left: "calc(0vw + 150px)",
                            position: "absolute",
                            top: "calc(50vh + 150px)",
                            width: 150,
                        }}
                    />
                    <CraneIcon
                        animate={true}
                        sx={{
                            height: 150,
                            left: "calc(100vw - 300px)",
                            position: "absolute",
                            top: "calc(50vh - 200px)",
                            width: 300,
                        }}
                    />
                </>
            </>
        );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default MaintenanceMode;
