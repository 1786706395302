import { pendingFunc } from "@src/features/pending/pending-slice";
import { useAppDispatch } from "@src/hooks/redux";
import { AppDispatch } from "@src/store";
import React, { DependencyList, useCallback, useEffect, useState } from "react";

type SetterFunc<T> = (value: T) => void;
type FetcherFunc<T> = () => Promise<T>;
type Loading = boolean;

export type UpdaterFunc = () => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyUpdaterFunc: UpdaterFunc = () => {};

// TODO: Should it return an object instead of an array?
export const useDataFetcher = <T>(
    setter: SetterFunc<T>,
    fetcher: FetcherFunc<T>,
    dependencies: DependencyList = [],
    fetchWrapper: (dispatch: AppDispatch, func: () => Promise<T>) => Promise<T> = pendingFunc,
): [UpdaterFunc, Loading] => {
    const dispatch = useAppDispatch();
    const [updateValue, triggerUpdate] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchWrapper(dispatch, fetcher);
                setter(data);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue, dispatch, ...dependencies]);

    const updater = useCallback(() => triggerUpdate(Date.now()), [triggerUpdate]);

    return [updater, loading];
};

// TODO: Should it return an object instead of an array?
export const useStateWithDataFetcher = <T>(
    defaultValue: T,
    fetcher: FetcherFunc<T>,
    dependencies: DependencyList = [],
    fetchWrapper: (dispatch: AppDispatch, func: () => Promise<T>) => Promise<T> = pendingFunc,
): [T, React.Dispatch<React.SetStateAction<T>>, UpdaterFunc, Loading] => {
    const [data, setData] = useState<T>(defaultValue);
    const [dataFetcher, loading] = useDataFetcher(setData, fetcher, dependencies, fetchWrapper);
    return [data, setData, dataFetcher, loading];
};
