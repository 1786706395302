import { Address } from "@src/api/addresses";
import { ApiUser } from "@src/api/api-users";
import { BusinessArea } from "@src/api/business-areas";
import { UserDTO } from "@src/api/creditors";
import { CreditorDocument, DebitorDocument } from "@src/api/documents";
import { Job } from "@src/api/jobs";
import { Product, ProductCategory } from "@src/api/products";
import { Team } from "@src/api/teams";
import { VatDTO } from "@src/api/vats";
import { FDWebsocketContext } from "@src/features/WebsocketURLProvider";
import { CompanyDTO, Payment } from "@src/types/Payment";
import { Role } from "@src/types/Roles";
import { User } from "@src/types/Users";
import { useContext } from "react";
import useWebSocket from "react-use-websocket";
import { WebSocketHook } from "react-use-websocket/src/lib/types";

export enum WSTitleEnum {
    CREDITOR_DOCUMENT_UPDATED = "creditor_document_updated",
    DEBITOR_DOCUMENT_UPDATED = "debitor_document_updated",
}

export type WSTitleType = `${WSTitleEnum}` | string;

export type WebsocketMessage = {
    channel: string;
    payload: {
        title: WSTitleType;
        data:
            | CreditorDocument
            | DebitorDocument
            | Job
            | CompanyDTO
            | Product
            | ProductCategory
            | Payment
            | BusinessArea
            | VatDTO
            | UserDTO
            | User
            | ApiUser
            | Team
            | Role
            | Address
            | null;
    };
};

/**
 * This hook provides a websocket connection to the FD websocket server. It uses the FDWebsocketContext to get the
 * websocket URL.
 */
const useFDWebsocket = (): WebSocketHook<WebsocketMessage> => {
    const socketUrl = useContext(FDWebsocketContext);

    return useWebSocket<WebsocketMessage>(socketUrl, { retryOnError: true, share: true });
};

export default useFDWebsocket;
