import { Logout, Settings, SupervisedUserCircle } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    useTheme,
} from "@mui/material";
import { selectImpersonatedUser, setImpersonated } from "@src/features/impersonate/impersonation-slice";
import { selectProfile } from "@src/features/profile/profile-slice";
import { useAppDispatch, useAppSelector } from "@src/hooks/redux";
import useShowSuccessSnackbar from "@src/hooks/success-snackbar";
import { post } from "@src/utils/request";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const UserMenu = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(selectProfile);
    const showSuccess = useShowSuccessSnackbar();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();

    const impersonatedUser = useAppSelector(selectImpersonatedUser);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleStopImpersonation = async () => {
        dispatch(setImpersonated(null));
        await post("/internalApi/users/removeImpersonateState");
        showSuccess(t("pages.users.imitate-stop-success"));
        window.location.pathname = "/system/users";
    };

    return (
        <Box sx={{ flexGrow: 0, ml: 2 }}>
            <Tooltip title={t("component.user-menu.open-user-settings")}>
                <IconButton onClick={handleOpenUserMenu}>
                    {impersonatedUser === null ? (
                        <Avatar alt={profile.user}>
                            <ManageAccountsIcon />
                        </Avatar>
                    ) : (
                        <Avatar
                            alt={profile.user}
                            sx={{ bgcolor: theme.palette.warning.main }}
                        >
                            <SupervisedUserCircle />
                        </Avatar>
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
                sx={{ mt: "45px" }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
            >
                <MenuItem disabled={true}>
                    <ListItemText>
                        {profile.firstName} 
                        {" "}
                        {profile.lastName} 
                        {" "}
                        {`(${profile.user})`}
                    </ListItemText>
                </MenuItem>
                {impersonatedUser && (
                    <MenuItem disabled={true}>
                        <ListItemText>
                            {t("component.user-menu.imitating", {
                                name: impersonatedUser,
                            })}
                        </ListItemText>
                    </MenuItem>
                )}
                <Divider />
                <MenuItem
                    component={NavLink}
                    onClick={handleCloseUserMenu}
                    to={"/profile"}
                >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("component.user-menu.user-settings")}</ListItemText>
                </MenuItem>
                {impersonatedUser === null ? (
                    <MenuItem
                        component={NavLink}
                        reloadDocument={true}
                        to="/logout"
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("component.user-menu.logout")}</ListItemText>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={handleStopImpersonation}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t("component.user-menu.stop-impersonation")}</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default React.memo(UserMenu);
