import { ApiResponse } from "@src/api/types/ApiResponse";
import { CinnamonID } from "@src/api/types/CinnamonID";
import { PaymentsFormInputs } from "@src/components/forms/payments/PaymentsForm";
import { FilterType } from "@src/pages/payments/PaymentsShared";
import { CreditorPaymentDTO, DebitorPaymentDTO, Payment } from "@src/types/Payment";
import argsBuilder from "@src/utils/args-builder";
import { deleteRelatedItemsFromCacheStorage } from "@src/utils/cache-storage-api";
import { get, post, put } from "@src/utils/request";

export const fetchPayment = async (id: CinnamonID): Promise<Payment> => {
    return get(`/internalApi/payments/${id}`);
};

export const fetchPayments = async (args: {
    company?: CinnamonID | null;
    dateFrom?: string | null;
    dateTo?: string | null;
    descriptions?: string | null;
    page?: number;
    pageSize?: number;
    sort?: string | null;
    type?: FilterType | null;
}): Promise<ApiResponse<Payment>> => {
    const parsedArgs = argsBuilder(args);
    return get(`/internalApi/payments${parsedArgs}`);
};

export const createPayment = async (body: PaymentSubmitData): Promise<Payment> => {
    const res = await post<Payment>("/internalApi/payments", { body });
    await burstPaymentCaches();
    return res;
};

export const updatePayment = async (id: CinnamonID, body: PaymentSubmitData): Promise<Payment> => {
    const res = await put<Payment>(`/internalApi/payments/${id}`, { body });
    await burstPaymentCaches();
    return res;
};

export const burstPaymentCaches = async () => {
    return deleteRelatedItemsFromCacheStorage("/internalApi/payments");
};

interface PaymentSubmitData extends Omit<PaymentsFormInputs, "creditorPayments" | "debitorPayments"> {
    debitorPayments: DebitorPaymentDTO[];
    creditorPayments: CreditorPaymentDTO[];
}
